import { fetchAuthSession } from 'aws-amplify/auth';
import { joinUrls } from '../join-url';

const getCustomInvoiceURL = async ({
  invoice_id,
  subscription_id,
}: {
  invoice_id: string;
  subscription_id: string;
}): Promise<{ url: string }> => {
  const token = (await fetchAuthSession()).tokens?.idToken?.toString();

  const response = await fetch(
    joinUrls(process.env.REACT_APP_API_ENDPOINT!, 'http/custom-invoice'),
    {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        invoice: {
          invoice_id,
          subscription_id,
        },
      }),
    }
  );

  const data = await response.json();

  return data;
};

export { getCustomInvoiceURL };
