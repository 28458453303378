import register from '../../images/register.png';
import copypaste from '../../images/copy-paste.png';
import trackleads from '../../images/track-leads.png';

import registerW from '../../images/register.webp';
import copypasteW from '../../images/copy-paste.webp';
import trackleadsW from '../../images/track-leads.webp';

import { ImgW } from '../Common';
import { Link } from 'react-router-dom';

const Features = () => {
  return (
    <section className='the-watch mt-20' id='features'>
      <div className='container m-auto'>
        <div className='greencheck'>
          <div className='v3 feature-header'>
            <p className='wp-title text-center text-4xl font-medium'>
              Get started in 3 simple steps
            </p>
            <p className='text-center mt-1 text-md'>
              We said, it is simple didn't we?
            </p>
          </div>
          <div className='flex md:flex-row md:flex-nowrap flex-wrap justify-center mt-2'>
            <div className='md:basis-1/3 text-center'>
              <p className='w-[45px] leading-[40px] rounded-[50%] text-[32px] border-2 border-[#666] m-auto'>
                1
              </p>
              <ImgW
                src={registerW}
                fallback={register}
                width='250'
                alt='register for free'
              />
              <h4 className='text-primary'>
                <Link
                  className='text-[#6b30e9] text-md font-medium cursor-pointer'
                  to='/#prices'
                >
                  Register for FREE
                </Link>
              </h4>
              <p>Takes literally seconds to register</p>
            </div>
            <div className='md:basis-1/3 text-center'>
              <p className='w-[45px] leading-[40px] rounded-[50%] text-[32px] border-2 border-[#666] m-auto'>
                2
              </p>
              <ImgW
                src={copypasteW}
                fallback={copypaste}
                width='250'
                alt='copy paste the javascript snippet'
              />
              <p className='text-[#6b30e9] text-md font-medium'>
                Place Tracker in Your Site
              </p>
              <p>
                Copy paste the javascript snippet generated in your website.
              </p>
            </div>
            <div className='md:basis-1/3 text-center'>
              <p className='w-[45px] leading-[40px] rounded-[50%] text-[32px] border-2 border-[#666] m-auto'>
                3
              </p>
              <ImgW
                src={trackleadsW}
                fallback={trackleads}
                width='250'
                alt='track leads'
              />
              <p className='text-[#6b30e9] text-md font-medium'>Track Leads</p>
              <p>Your forms will be automatically filled with the lead data.</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Features;
