function ErrorScreen() {
  // TODO Integrate with Sentry or a similar service

  return (
    <div className='flex flex-col w-full flex-grow p-10 items-center justify-center overflow-y-auto'>
      <div className='flex flex-col items-center gap-2.5'>
        <div className='text-center text-title-1 text-[40px] font-bold tracking-tight'>
          Uh oh
        </div>
        <div className='flex flex-col items-center gap-[3px]'>
          <div className='text-center text-gray-700 text-2xl font-medium leading-normal tracking-tight'>
            There was an error on our end.
          </div>
          <div className='text-center text-gray-500 text-base leading-normal tracking-tight font-medium'>
            Don't worry, it's not you - it's us. We apologize for the
            inconvenience.
          </div>
        </div>
        <button className='px-5 py-2.5 bg-blue-600 rounded-[5px] justify-center items-center gap-[5px] inline-flex hover:outline-none'>
          <a
            href={'/'}
            className='text-center text-white text-sm font-semibold leading-snug hover:text-white'
          >
            Back to Home
          </a>
        </button>
      </div>
    </div>
  );
}

export default ErrorScreen;
