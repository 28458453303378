import { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
  updateUserAttributes,
  fetchUserAttributes,
  fetchAuthSession,
  signOut,
  getCurrentUser,
  signIn,
} from 'aws-amplify/auth';
import VerificationInput from 'react-verification-input';
import Modal from '../../Modal';
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import { changeEmail } from './change-email.api';

function ChangeEmailModal({
  changeEmailModal,
  setChangeEmailModal,
}: Readonly<{
  changeEmailModal: boolean;
  setChangeEmailModal: (state: boolean) => void;
}>) {
  const [email, setEmail] = useState<string>('');
  const [currentStep, setCurrentStep] = useState(0);

  const changeEmailMutation = useMutation({
    mutationKey: ['changeEmail'],
    mutationFn: changeEmail,
    onSuccess: () => {
      setCurrentStep(3);
      setTimeout(() => logOutAndRedirect(), 4000);
    },
  });

  const navigate = useNavigate();

  const sendVerificationCode = async (email: string) => {
    const userAttributes = await fetchUserAttributes();
    await updateUserAttributes({
      userAttributes: {
        ...userAttributes,
        email,
      },
    });
  };

  const handleSubmitEmail = async (values: any) => {
    const user = await fetchUserAttributes();
    const cognitoUserSession = (user as any).getSignInUserSession();
    try {
      changeEmailMutation.mutate({
        code: values.code,
        accessToken: (await fetchAuthSession()).tokens?.idToken?.toString()!,
      });
    } catch (error: any) {
      throw new Error(error);
    }
  };

  const logOutAndRedirect = async () => {
    await signOut();
    navigate('/');
  };
  const handleCloseModal = async () => {
    setChangeEmailModal(false);
    if (currentStep === 3) {
      await logOutAndRedirect();
    }
  };
  return (
    <Modal
      isOpen={changeEmailModal}
      onClose={() => {
        if (!changeEmailMutation.isPending) handleCloseModal();
      }}
      onAfterClosed={() => {}}
      title={<></>}
    >
      <div
        className={`lg:w-[700px] md:w-[500px] w-[400px] px-10 md:px-20 p-5 font-[poppins] ${
          currentStep === 3 && 'success-bg'
        }`}
      >
        {currentStep === 0 && (
          <PasswordConfirmationForm
            onSubmit={async () => {
              setCurrentStep(1);
            }}
          />
        )}
        {currentStep === 1 && (
          <EmailInputForm
            onSubmit={async (values) => {
              await sendVerificationCode(values.email);
              setEmail(values.email);
              setCurrentStep(2);
            }}
          />
        )}
        {currentStep === 2 && (
          <CodeInputForm
            email={email}
            onSubmit={handleSubmitEmail}
            handleNotMyEmail={() => setCurrentStep(1)}
          />
        )}
        {currentStep === 3 && (
          <div className='flex flex-col'>
            <div className='row p-6'>
              <i className='fa text-white fa-check-circle text-[9rem]'></i>
            </div>
            <div className='flex flex-col gap-2 items-center text-center'>
              <div className='row text-white text-base font-normal'>
                Your email has been successfully updated
              </div>
              <div className='row text-white text-sm font-light'>
                You'll be logged out now. Please log in again to see your
                changes.
              </div>
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
}
const PasswordConfirmationForm = ({
  onSubmit,
}: {
  onSubmit: (value: any) => Promise<void>;
}) => {
  const [loading, setLoading] = useState(false);
  const formik = useFormik({
    initialValues: {
      password: '',
    },
    validationSchema: Yup.object({
      password: Yup.string().required('This field is required'),
    }),
    onSubmit: async (values) => {
      try {
        setLoading(true);
        const user = await getCurrentUser();
        const currentUser = await signIn({
          username: user.username,
          password: values.password,
        });
        await onSubmit(values.password);
      } catch (error: any) {
        console.error('Reauthentication error:', error);
        if (error.message === 'Incorrect username or password.') {
          formik.setErrors({ password: 'Incorrect password' });
        } else {
          formik.setErrors({ password: error.message });
        }
      } finally {
        setLoading(false);
      }
    },
  });
  return (
    <form onSubmit={formik.handleSubmit} className='flex flex-col gap-4'>
      <p className='text-base font-light'>Enter your password to continue</p>
      <div className='flex flex-col items-start'>
        <input
          id='password'
          name='password'
          type='password'
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.password}
          className='g-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5'
        />
        {formik.touched.password && formik.errors.password ? (
          <div className='text-red-400 mt-2 text-sm'>
            {formik.errors.password}
          </div>
        ) : null}
      </div>

      <button
        type='submit'
        disabled={loading}
        className='btn btn-default btn-block btn-sm bg-[#0084ff] w-full'
      >
        {loading ? <i className='fa fa-spin fa-spinner' /> : 'Continue'}
      </button>
    </form>
  );
};
const CodeInputForm = ({
  onSubmit,
  email,
  handleNotMyEmail,
}: {
  email: string;
  handleNotMyEmail: (event: any) => any;
  onSubmit: (values: any) => Promise<void>;
}) => {
  const [loading, setLoading] = useState(false);
  const formik = useFormik({
    initialValues: { code: '' },
    validationSchema: Yup.object({
      code: Yup.string()
        .matches(/^[0-9]+$/, 'Code must consist of digits only')
        .required('This field is required')
        .min(6, 'Code must be exactly 6 digits')
        .max(6, 'Code must be exactly 6 digits'),
    }),
    onSubmit: async (values) => {
      setLoading(true);
      try {
        await onSubmit(values);
      } catch (error: any) {
        console.error('Error updating email:', error);
        formik.setErrors({ code: error.message });
      } finally {
        setLoading(false);
      }
    },
  });

  return (
    <form
      onSubmit={formik.handleSubmit}
      className='flex flex-col gap-4 items-start'
    >
      <p className='text-base font-light'>
        To verify the new email, please enter the six-digit code we sent to{' '}
        <span>{email}, </span>{' '}
        <span
          onClick={handleNotMyEmail}
          className='text-blue-500 cursor-pointer'
        >
          not you?
        </span>
      </p>
      <div className='flex flex-col self-center items-center'>
        <VerificationInput
          inputProps={{ className: 'opacity-0' }}
          classNames={{
            character:
              'border-2 border-gray-200 rounded text-xl font-semibold text-center flex flex-col items-center justify-center',
            characterInactive: 'bg-white text-blue-500',
            characterSelected: 'border-blue-500 bg-blue-100',
            characterFilled: 'border-blue-600 bg-blue-50 text-blue-600',
          }}
          onChange={(val) => {
            formik.setFieldValue('code', val);
          }}
          value={formik.values.code}
          validChars='0-9'
        />
        {formik.touched.code && formik.errors.code ? (
          <div className='text-red-400 mt-2 text-sm'>{formik.errors.code}</div>
        ) : null}
      </div>
      <button
        type='submit'
        disabled={loading}
        className='btn btn-default btn-block btn-sm bg-[#0084ff] w-full'
      >
        {loading ? <i className='fa fa-spin fa-spinner' /> : 'Continue'}
      </button>
    </form>
  );
};

const EmailInputForm = ({
  onSubmit,
}: {
  onSubmit: (values: any) => Promise<void>;
}) => {
  const [loading, setLoading] = useState(false);
  const formik = useFormik({
    initialValues: {
      email: '',
      confirmEmail: '',
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email('Invalid email address')
        .required('This field is required'),
      confirmEmail: Yup.string()
        .oneOf([Yup.ref('email'), undefined], 'Emails must match')
        .required('This field is required'),
    }),
    onSubmit: async (values) => {
      setLoading(true);
      try {
        await onSubmit(values);
      } catch (error: any) {
        console.error('Error updating email:', error);
        formik.setErrors({ email: error.message });
      } finally {
        setLoading(false);
      }
    },
  });
  return (
    <form onSubmit={formik.handleSubmit} className='flex flex-col gap-4'>
      <div className='flex flex-col items-start'>
        <input
          id='email'
          name='email'
          type='email'
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.email}
          className='g-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5'
          placeholder='New email'
        />
        {formik.touched.email && formik.errors.email ? (
          <div className='text-red-400 mt-2 text-sm'>{formik.errors.email}</div>
        ) : null}
      </div>

      <div className='flex flex-col items-start'>
        <input
          name='confirmEmail'
          type='email'
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.confirmEmail}
          className='g-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5'
          placeholder='Confirm new email'
        />
        {formik.touched.confirmEmail && formik.errors.confirmEmail ? (
          <div className='text-red-400 mt-2 text-sm'>
            {formik.errors.confirmEmail}
          </div>
        ) : null}
      </div>

      <button
        type='submit'
        disabled={loading}
        className='btn btn-default btn-block btn-sm bg-[#0084ff] w-full'
      >
        {loading ? <i className='fa fa-spin fa-spinner' /> : 'Continue'}
      </button>
    </form>
  );
};
export default ChangeEmailModal;
