import ProductDesc from './ProductDesc';
import Modal from './Modal';
import { useAuthStore } from '../features/auth/AuthStore';

const Payment = () => {
  const isPaymentModalVisible = useAuthStore(
    (state) => state.isPaymentModalVisible
  );
  const hidePaymentModal = useAuthStore((state) => state.hidePaymentModal);

  return (
    <Modal
      isOpen={isPaymentModalVisible}
      title={<></>}
      onClose={() => hidePaymentModal()}
      className=''
      maskClassName=''
      onAfterClosed={() => {}}
    >
      <div
        id='payment'
        className='lg:w-[700px] md:w-[500px] w-[400px] px-10 py-4'
      >
        <ProductDesc />
      </div>
    </Modal>
  );
};

export default Payment;
