import cx from 'classnames';

export const ImgW = ({
  src,
  fallback,
  type = 'image/webp',
  alt,
  className = '',
  ...delegated
}: {
  src: string;
  fallback: string;
  type?: string;
  alt: string;
  className?: string;
  [key: string]: any;
}) => {
  return (
    <picture>
      <source srcSet={src} type={type} />
      <img
        className={cx('inline', className)}
        src={fallback}
        alt={alt}
        {...delegated}
      />
    </picture>
  );
};

export const dateReformat = function dateReformat(ts: number) {
  let date = new Date(ts);
  const month = date.toLocaleString('default', { month: 'long' });
  const day = date.getDate();
  const year = date.getFullYear();
  return `${month} ${day}, ${year}`;
};
