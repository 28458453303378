import { create } from 'zustand';
import { PlanType } from '../../consts';

type SelectedPlan = {
  selected_plan: PlanType;
  product_price: number;
  original_price: number;
  paypal_plan_id: string;
  stripe_plan_id: string;
  msrp: number;
  title: string;
  quantity?: number;
  membership?: boolean;
  substitle?: string;
  multiplier?: boolean;

  setSelectedPlan?: (selectedPlan: SelectedPlan) => void;
};

const useSelectedPlanStore = create<SelectedPlan>((set) => ({
  selected_plan: 'basic',
  product_price: 10.0,
  original_price: 10.0,
  paypal_plan_id: '2MF95308E09061601MFAYCRA',
  stripe_plan_id: 'price_1IW4HRAazLOJAQUCmwoqq6l0',
  msrp: 20,
  title: 'Basic',
  membership: true,
  quantity: 1,
  multiplier: false,

  setSelectedPlan: (selectedPlan: SelectedPlan) => {
    set(() => ({ ...selectedPlan }));
  },
}));

export { useSelectedPlanStore };
