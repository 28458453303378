import { FormEvent, useRef, useState } from 'react';
import { fetchUserAttributes, getCurrentUser, signIn } from 'aws-amplify/auth';
import SimpleReactValidator from 'simple-react-validator';
import Modal from '../Modal';
import { useAuthStore } from '../../features/auth/AuthStore';

const { gtag, $crisp } = window;

const Login = () => {
  const validator = useRef(
    new SimpleReactValidator({
      element: (message: string, className: string) => (
        <div className={className}>{message}</div>
      ),
    })
  );

  const isAuthenticated = useAuthStore((state) => state.isAuthenticated);
  const showPaymentModal = useAuthStore((state) => state.showPaymentModal);
  const setAuthUser = useAuthStore((state) => state.setAuthUser);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const [isProcessing, setIsProcessing] = useState(false);

  const hideLoginForm = useAuthStore((state) => state.hideLoginForm);
  const isLoginFormVisible = useAuthStore((state) => state.isLoginFormVisible);
  const showForgotPasswordForm = useAuthStore(
    (state) => state.showForgotPasswordForm
  );
  const setUserAttributes = useAuthStore((state) => state.setUserAttributes);
  const makeUserAuthenticated = useAuthStore(
    (state) => state.makeUserAuthenticated
  );
  const setPendingUser = useAuthStore((state) => state.setPendingUser);

  const onLogin = async (e: FormEvent) => {
    e.preventDefault();
    setIsProcessing(true);

    if (validator.current.allValid()) {
      try {
        const response = await signIn({ username: email, password });

        if (!response.isSignedIn) {
          setErrorMsg(
            'User is not confirmed. Please go to your mail box and verify your email address.'
          );
          setIsProcessing(false);
          return;
        }

        const user = await getCurrentUser();

        const userAttributes = await fetchUserAttributes();
        setUserAttributes(userAttributes);
        console.log(userAttributes);

        setPendingUser({
          first_name: userAttributes.given_name,
          email: userAttributes.email,
        });

        setAuthUser(user);
        makeUserAuthenticated();

        gtag('event', 'login', {
          email,
        });
        $crisp.push(['set', 'user:nickname', [userAttributes.given_name]]);
        $crisp.push(['set', 'user:email', [userAttributes.email]]);
        setErrorMsg('');
        setIsProcessing(false);
      } catch (error: any) {
        setErrorMsg(error.message);
        setIsProcessing(false);
      }
    } else {
      validator.current.showMessages();
      setIsProcessing(false);
    }
  };

  const onForgotPassword = (event: FormEvent) => {
    event.preventDefault();
    hideLoginForm();
    showForgotPasswordForm();
  };

  const onFreeClick = () => {
    showPaymentModal();
    hideLoginForm();
  };

  // TODO: remove this in future | ideally login should have it's own route URL
  if (isAuthenticated || !isLoginFormVisible) {
    return null;
  }

  return (
    <Modal
      isOpen={true}
      title={<>Sign in to your account</>}
      onClose={() => hideLoginForm()}
      onAfterClosed={() => {}}
    >
      <div className='lg:w-[700px] md:w-[500px] w-[400px] px-10 py-4'>
        <form className='space-y-6' onSubmit={onLogin}>
          <div className='text-left'>
            <label
              htmlFor='email'
              className='block text-sm font-medium leading-6 text-gray-900'
            >
              Email address
            </label>
            <div className='mt-2'>
              <input
                id='email'
                name='email'
                type='email'
                autoComplete='email'
                required
                onChange={(e) => setEmail(e.target.value)}
                value={email}
                tabIndex={1}
                className='block w-full px-3 py-2 bg-white border border-slate-300 rounded-md text-sm shadow-sm placeholder-slate-400
                focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500
                disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none
                focus:invalid:border-pink-500 focus:invalid:ring-pink-500'
              />
              {validator.current.message('email', email, 'required|email', {
                className: 'text-red-500 text-left',
              })}
            </div>
          </div>

          <div>
            <div className='flex items-center justify-between'>
              <label
                htmlFor='password'
                className='block text-sm font-medium leading-6 text-gray-900'
              >
                Password
              </label>
              <div className='text-sm'>
                <button
                  type='button'
                  onClick={onForgotPassword}
                  className='font-semibold text-indigo-600 hover:text-indigo-500'
                >
                  Forgot password?
                </button>
              </div>
            </div>
            <div className='mt-2'>
              <input
                id='password'
                name='password'
                type='password'
                autoComplete='current-password'
                required
                onChange={(e) => setPassword(e.target.value)}
                value={password}
                tabIndex={2}
                className='block w-full px-3 py-2 bg-white border border-slate-300 rounded-md text-sm shadow-sm placeholder-slate-400
                focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500
                disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none
                focus:invalid:border-pink-500 focus:invalid:ring-pink-500'
              />
              {validator.current.message('password', password, 'required', {
                className: 'text-red-500 text-left',
              })}
            </div>
          </div>

          <div>
            <button
              type='submit'
              className='flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'
            >
              {isProcessing ? (
                <svg
                  aria-hidden='true'
                  className='w-8 h-8 text-gray-200 animate-spin fill-white'
                  viewBox='0 0 100 101'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    d='M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z'
                    fill='currentColor'
                  />
                  <path
                    d='M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z'
                    fill='currentFill'
                  />
                </svg>
              ) : (
                'Sign in'
              )}
            </button>
            <p className='text-sm font-light text-left mt-4'>
              Don't have an account yet?{' '}
              <button
                className='font-medium text-indigo-600 hover:underline dark:text-primary-500'
                onClick={onFreeClick}
              >
                Sign up
              </button>
            </p>

            {errorMsg && (
              <p className='text-red-500 text-left text-sm font-bold'>
                {errorMsg}
              </p>
            )}
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default Login;
