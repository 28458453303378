import { Link } from 'react-router-dom';

const Schedule = () => {
  return (
    <section className='grey-section' id='schedule-expert'>
      <div className='container m-auto text-center'>
        <h1 className='text-4xl font-medium m-b-20'>
          Schedule a 30-minutes FREE discovery call with an expert?
        </h1>

        <p className='pb-2'>
          If you're not sure where to start, or you're not sure if you're ready
          to take the plunge, you can always schedule a call with an expert.
        </p>
        <p className='pb-2'>
          At the very least, you'll get some great advice and a better
          understanding of what you need to do to get started.
        </p>
        <p className='pb-2'>
          You can also ask about the best way to get started with your business.
        </p>
        <p className='pb-4'>
          You might be surprised at how much you can learn from an expert.
        </p>

        <Link to={'/schedule'} className='btn btn-warning btn-sm px-6 py-3'>
          Schedule Now
        </Link>
      </div>
    </section>
  );
};

export default Schedule;
