import { FaCheck } from 'react-icons/fa';
import { ImCross } from 'react-icons/im';

export default function PlanStatus({
  status,
}: Readonly<{
  status: 'active' | 'trialing' | 'canceled';
}>) {
  if (status === 'active' || status === 'trialing') {
    return (
      <div
        className={`inline-flex items-center px-3 py-1 rounded-full gap-x-2 ${
          status === 'active'
            ? 'text-emerald-500 bg-emerald-100/60'
            : 'text-orange-500 bg-orange-100/60'
        }`}
      >
        <FaCheck size={10} />
        <h2 className='text-sm font-normal capitalize'>{status}</h2>
      </div>
    );
  }

  if (status === 'canceled') {
    return (
      <div className='inline-flex items-center px-3 py-1 text-red-500 rounded-full gap-x-2 bg-red-100/60'>
        <ImCross size={8} />
        <h2 className='text-sm font-normal'>Canceled</h2>
      </div>
    );
  }

  return (
    <div className='inline-flex items-center px-3 py-1 text-orange-500 rounded-full gap-x-2 bg-orange-100/60'>
      <svg
        width='12'
        height='12'
        viewBox='0 0 12 12'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M6 1V7'
          stroke='currentColor'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M6 8V9'
          stroke='currentColor'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
      <h2 className='text-sm font-normal'>Expiring</h2>
    </div>
  );
}
