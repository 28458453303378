import { fetchAuthSession } from 'aws-amplify/auth';
import { joinUrls } from '../join-url';

const getAccountInfo = async () => {
  const token = (await fetchAuthSession()).tokens?.idToken?.toString();

  const response = await fetch(
    joinUrls(process.env.REACT_APP_API_ENDPOINT!, 'http/myaccount'),
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  const data = response.json();

  if (!response.ok) {
    throw new Error((data as any).message);
  }

  return data;
};

export { getAccountInfo };
