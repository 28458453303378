import Features from './Features';
import Prices from './Prices';
import Schedule from './Schedule';
import Faq from './FAQ';
import Contact from './Contact';
import Hero from './Hero';
import Login from './Login';
import ForgotPassword from './ForgotPassword';

export default function Home() {
  return (
    <>
      <Login />
      <ForgotPassword />
      <Hero />
      <Features />
      <Prices />
      <Schedule />
      <Faq />
      <Contact />
    </>
  );
}
