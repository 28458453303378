import { useState, useEffect } from 'react';
import { confirmSignUp } from 'aws-amplify/auth';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Modal from './Modal';

const ConfirmUser = () => {
  const [error, setError] = useState(true);
  const [msg, setMsg] = useState('');
  const [showForm, setShowForm] = useState(false);
  const navigate = useNavigate();

  const [searchParams, _] = useSearchParams();

  useEffect(() => {
    const asyncFunc = async () => {
      const confirmation_code = searchParams.get('confirmation_code');
      const client_id = searchParams.get('client_id');
      const user_name = searchParams.get('user_name');

      if (confirmation_code !== '' && client_id !== '' && user_name !== '') {
        try {
          await confirmSignUp({
            username: user_name!,
            confirmationCode: confirmation_code!,
          });

          setError(false);
        } catch (error: any) {
          setMsg(error.message);
        }
        setShowForm(true);
      }
    };
    asyncFunc();
  }, []);

  const onCloseModal = (event: any) => {
    event.preventDefault();
    setShowForm(false);
    navigate('/');
  };

  return (
    <Modal
      isOpen={showForm}
      title={<>Confirm User</>}
      onClose={onCloseModal}
      onAfterClosed={() => {}}
    >
      <div
        id='confirm-user-popup'
        className='lg:w-[700px] md:w-[500px] w-[400px] px-10 py-4'
      >
        <div
          className={`col-md-12 ${
            error ? ' danger-bg' : 'success-bg'
          } fs-10 text-white`}
        >
          <i
            className={`fa text-white ${
              error ? ' fa-exclamation-circle' : ' fa-check-circle'
            }`}
          ></i>
        </div>
        <div className='col-md-12 p-t-20 p-b-20'>
          {error ? (
            <div>
              <h1>Some problem occurred :( </h1>
              <p>{msg}</p>
            </div>
          ) : (
            <div>
              <h1>Great!</h1>
              <p>Your account has been verified successfully.</p>
            </div>
          )}
          <button
            onClick={onCloseModal}
            className={`flex w-full justify-center rounded-md mt-6 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 ${
              error
                ? ' bg-red-600 hover:bg-red-500'
                : ' bg-indigo-600 hover:bg-indigo-500'
            }`}
          >
            OK
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmUser;
