import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Modal from '../../Modal';
import { useQuery } from '@tanstack/react-query';
import { getInvoiceListYearWise } from '../invoice-list.api';
import { InvoiceRow } from './InvoiceRow';

export default function Billings() {
  const { data: invoiceListByYear, isLoading: isInvoicesLoading } = useQuery({
    queryKey: ['invoiceListYearWise'],
    queryFn: getInvoiceListYearWise,
  });

  const invoicesByYear = invoiceListByYear || {};

  const [openYear, setOpenYear] = useState<number | null>(null);

  useEffect(() => {
    const topYear = Math.max(...Object.keys(invoicesByYear).map(Number));
    setOpenYear(topYear);
  }, [invoiceListByYear]);

  const [receiptUnavailableModal, setReceiptUnavailableModal] = useState(false);

  return (
    <div className='relative overflow-x-auto m-auto'>
      <Modal
        isOpen={receiptUnavailableModal}
        onClose={() => setReceiptUnavailableModal(false)}
        onAfterClosed={() => {}}
        title={<></>}
      >
        <div className='flex flex-col p-3 font-[poppins]'>
          <div className='text-center w-full bg-white z-[500] relative text-sm'>
            To download the invoice, please input your billing information under{' '}
            <Link className='text-blue-600' to={'/infos/myaccounts'}>
              My Account
            </Link>
            .
          </div>
        </div>
      </Modal>

      {isInvoicesLoading && (
        <div className='text-center'>
          <div className='fa-3x'>
            <i className='fa fa-spinner fa-spin'></i>
            <p>Please wait while we are pulling your account data...</p>
          </div>
        </div>
      )}

      {!isInvoicesLoading && Object.keys(invoicesByYear).length === 0 && (
        <div className='text-center'>
          You do not have any invoices yet. Your invoices will appear here when
          they become available.
        </div>
      )}

      {!isInvoicesLoading &&
        Object.keys(invoicesByYear)
          .map(Number)
          .sort((a, b) => b - a)
          .map((year) => (
            <div key={year} className='border-t'>
              <button
                className={`w-full text-left py-4 px-6 font-medium text-gray-900 ${
                  year === openYear ? 'bg-gray-200' : ''
                } `}
                onClick={() => setOpenYear(openYear === year ? null : year)}
              >
                {year}
              </button>
              {openYear === year && (
                <div className='px-6 pb-4'>
                  <table className='w-full text-sm text-left text-gray-500 mt-4'>
                    <thead className='text-xs text-gray-700 uppercase bg-gray-50'>
                      <tr>
                        <th scope='col' className='px-6 py-3'>
                          Amount
                        </th>
                        <th scope='col' className='px-6 py-3'>
                          Date
                        </th>
                        <th scope='col' className='px-6 py-3'>
                          Invoice
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {invoicesByYear[year].map((invoice: any) => (
                        <InvoiceRow
                          key={invoice.id}
                          invoice={invoice}
                          setReceiptUnavailableModal={
                            setReceiptUnavailableModal
                          }
                        />
                      ))}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
          ))}
    </div>
  );
}
