import { fetchAuthSession } from 'aws-amplify/auth';
import { joinUrls } from '../join-url';

const updateDomain = async ({
  license_key,
  domain,
}: {
  license_key: string;
  domain: string;
}): Promise<{
  error: boolean;
  message: string;
  domain?: string;
}> => {
  const token = (await fetchAuthSession()).tokens?.idToken?.toString();

  const response = await fetch(
    joinUrls(process.env.REACT_APP_API_ENDPOINT!, 'http/update-domain'),
    {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        license_key,
        domain,
      }),
    }
  );

  const data = await response.json();

  return data;
};

export { updateDomain };
