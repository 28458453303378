import { useState, useEffect } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { toast } from 'react-toastify';
import ChangeEmailModal from './ChangeEmailModal';
import { fetchAuthSession, updateUserAttributes } from 'aws-amplify/auth';
import { useAuthStore } from '../../../features/auth/AuthStore';

export default function MyAccounts() {
  const [loading, setLoading] = useState(false);
  const [errMsg, setErrMsg] = useState('');

  const userAttributes = useAuthStore((state) => state.userAttributes);
  const setUserAttributes = useAuthStore((state) => state.setUserAttributes);

  const validateSchema = Yup.object().shape({
    name: Yup.string().required('This field is required'),
    email: Yup.string()
      .email('Please enter a valid email')
      .required('This field is required'),
    companyName: Yup.string().required('This field is required'),
    address: Yup.string().required('This field is required'),
    vat: Yup.string().required('This field is required'),
  });

  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      companyName: '',
      address: '',
      vat: '',
    },
    validationSchema: validateSchema,
    onSubmit: async (values) => {
      setLoading(true);
      const { name, companyName, address, vat } = values;
      try {
        const _userAttributes = {
          'custom:name': name,
          'custom:company_name': companyName,
          'custom:address': address,
          'custom:vat': vat,
        };

        await updateUserAttributes({
          userAttributes: _userAttributes,
        });

        toast.success(`Billing info updated successfully`, {
          position: 'top-right',
        });

        // refresh auth token so userAttributes are updated
        await fetchAuthSession({forceRefresh: true});

        setUserAttributes({ email: userAttributes.email, ..._userAttributes });
      } catch (error: any) {
        setErrMsg(error?.response?.data?.message ?? 'Something went wrong');
        setLoading(false);
      }
      setLoading(false);
    },
  });

  useEffect(() => {
    formik.setFieldValue('email', userAttributes.email);
  }, [userAttributes.email]);

  useEffect(() => {
    formik.setFieldValue('name', userAttributes['custom:name']);
    formik.setFieldValue('companyName', userAttributes['custom:company_name']);
    formik.setFieldValue('address', userAttributes['custom:address']);
    formik.setFieldValue('vat', userAttributes['custom:vat']);
  }, [userAttributes]);

  let nameClass =
    formik.errors.name && formik.touched.name
      ? 'rounded-none rounded-r-lg bg-gray-50 border border-red-500 text-red-900 focus:ring-blue-500 focus:border-blue-500 block flex-1 min-w-0 w-full p-2.5 placeholder-red-700'
      : 'rounded-none rounded-r-lg bg-gray-50 border border-gray-300 text-gray-900 focus:ring-blue-500 focus:border-blue-500 block flex-1 min-w-0 w-full p-2.5';
  let emailClass =
    formik.errors.email && formik.touched.email
      ? 'rounded-none rounded-r-lg bg-gray-50 border border-red-500 text-red-900 focus:ring-blue-500 focus:border-blue-500 block flex-1 min-w-0 w-full p-2.5 placeholder-red-700'
      : 'rounded-none rounded-r-lg bg-gray-50 border border-gray-300 text-gray-900 focus:ring-blue-500 focus:border-blue-500 block flex-1 min-w-0 w-full p-2.5';
  let companyClass =
    formik.errors.companyName && formik.touched.companyName
      ? 'rounded-none rounded-r-lg bg-gray-50 border border-red-500 text-red-900 focus:ring-blue-500 focus:border-blue-500 block flex-1 min-w-0 w-full p-2.5 placeholder-red-700'
      : 'rounded-none rounded-r-lg bg-gray-50 border border-gray-300 text-gray-900 focus:ring-blue-500 focus:border-blue-500 block flex-1 min-w-0 w-full p-2.5';
  let vatClass =
    formik.errors.vat && formik.touched.vat
      ? 'rounded-none rounded-r-lg bg-gray-50 border border-red-500 text-red-900 focus:ring-blue-500 focus:border-blue-500 block flex-1 min-w-0 w-full p-2.5 placeholder-red-700'
      : 'rounded-none rounded-r-lg bg-gray-50 border border-gray-300 text-gray-900 focus:ring-blue-500 focus:border-blue-500 block flex-1 min-w-0 w-full p-2.5';
  const addressClass =
    formik.errors.address && formik.touched.address
      ? 'rounded-none rounded-r-lg bg-gray-50 border border-red-500 text-red-900 focus:ring-blue-500 focus:border-blue-500 block flex-1 min-w-0 w-full p-2.5 placeholder-red-700'
      : 'rounded-none rounded-r-lg bg-gray-50 border border-gray-300 text-gray-900 focus:ring-blue-500 focus:border-blue-500 block flex-1 min-w-0 w-full p-2.5';
  const [changeEmailModal, setChangeEmailModal] = useState(false);
  return (
    <div className='w-full sm:w-[70%] md:w-[80%] lg:w-[50%] m-auto'>
      <ChangeEmailModal
        changeEmailModal={changeEmailModal}
        setChangeEmailModal={setChangeEmailModal}
      />
      <p className='text-center text-[24px] font-medium my-10'>
        Welcome to My Account 👋
      </p>
      <div className='px-10'>
        {errMsg && (
          <div className='w-full py-3 px-5 bg-red-500 text-white text-center mb-2'>
            {errMsg}
          </div>
        )}

        <form onSubmit={formik.handleSubmit} method='POST'>
          <div className='flex mb-2'>
            <span className='inline-flex items-center px-3 text-sm text-gray-900 bg-gray-200 border border-r-0 border-gray-300 rounded-l-md'>
              <svg
                className='w-4 h-4 text-gray-500'
                aria-hidden='true'
                xmlns='http://www.w3.org/2000/svg'
                fill='currentColor'
                viewBox='0 0 20 20'
              >
                <path d='M10 0a10 10 0 1 0 10 10A10.011 10.011 0 0 0 10 0Zm0 5a3 3 0 1 1 0 6 3 3 0 0 1 0-6Zm0 13a8.949 8.949 0 0 1-4.951-1.488A3.987 3.987 0 0 1 9 13h2a3.987 3.987 0 0 1 3.951 3.512A8.949 8.949 0 0 1 10 18Z' />
              </svg>
            </span>
            <input
              type='text'
              id='website-admin'
              className={nameClass}
              placeholder={
                formik.errors.name && formik.touched.name
                  ? formik.errors.name
                  : 'Name'
              }
              name='name'
              value={formik.values.name}
              onChange={formik.handleChange}
            />
          </div>
          <div className='flex mb-2 relative'>
            <span className='inline-flex items-center px-3 text-sm text-gray-900 bg-gray-200 border border-r-0 border-gray-300 rounded-l-md'>
              <svg
                className='w-4 h-4 text-gray-500 dark:text-gray-400'
                aria-hidden='true'
                xmlns='http://www.w3.org/2000/svg'
                fill='currentColor'
                viewBox='0 0 20 16'
              >
                <path d='m10.036 8.278 9.258-7.79A1.979 1.979 0 0 0 18 0H2A1.987 1.987 0 0 0 .641.541l9.395 7.737Z' />
                <path d='M11.241 9.817c-.36.275-.801.425-1.255.427-.428 0-.845-.138-1.187-.395L0 2.6V14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2.5l-8.759 7.317Z' />
              </svg>
            </span>
            <input
              type='email'
              id='website-admin'
              name='email'
              disabled
              className={emailClass}
              placeholder={
                formik.errors.email && formik.errors.email
                  ? formik.errors.email
                  : 'Email address'
              }
              value={formik.values.email}
              onChange={formik.handleChange}
            />

            <div className='absolute -right-7 top-1/2 -translate-y-1/2 flex items-center justify-center'>
              <button onClick={() => setChangeEmailModal(true)}></button>
            </div>
          </div>
          <div className='flex mb-2'>
            <span className='inline-flex items-center px-3 text-sm text-gray-900 bg-gray-200 border border-r-0 border-gray-300 rounded-l-md'>
              <svg
                viewBox='0 0 24 24'
                fill='white'
                xmlns='http://www.w3.org/2000/svg'
                className='w-4 h-4 text-gray-500'
                aria-hidden='true'
              >
                <g id='SVGRepo_bgCarrier' strokeWidth='0'></g>
                <g
                  id='SVGRepo_tracerCarrier'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                ></g>
                <g id='SVGRepo_iconCarrier'>
                  {' '}
                  <path
                    d='M11 20H21V10C21 8.89543 20.1046 8 19 8H15M11 16H11.01M17 16H17.01M7 16H7.01M11 12H11.01M17 12H17.01M7 12H7.01M11 8H11.01M7 8H7.01M15 20V6C15 4.89543 14.1046 4 13 4H5C3.89543 4 3 4.89543 3 6V20H15Z'
                    stroke='currentColor'
                    strokeWidth='2'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                  ></path>{' '}
                </g>
              </svg>
            </span>
            <input
              type='text'
              id='website-admin'
              className={companyClass}
              placeholder={
                formik.errors.companyName && formik.touched.companyName
                  ? formik.errors.companyName
                  : 'Company Name'
              }
              name='companyName'
              value={formik.values.companyName}
              onChange={formik.handleChange}
            />
          </div>
          <div className='flex mb-2'>
            <span className='inline-flex items-center px-3 text-sm text-gray-900 bg-gray-200 border border-r-0 border-gray-300 rounded-l-md'>
              <svg
                className='w-4 h-4 text-gray-500'
                aria-hidden='true'
                viewBox='0 0 8.4666669 8.4666669'
                xmlns='http://www.w3.org/2000/svg'
                fill='currentColor'
              >
                <g id='SVGRepo_bgCarrier' strokeWidth='0'></g>
                <g
                  id='SVGRepo_tracerCarrier'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                ></g>
                <g id='SVGRepo_iconCarrier'>
                  <defs id='defs2'></defs>
                  <g id='layer1' transform='translate(0,-288.53332)'>
                    <path
                      d='m 15.996094,0.99609375 c -6.0632836,0 -10.9980445,4.93673065 -10.9980471,11.00000025 -3.8e-6,10.668737 10.3789061,18.779297 10.3789061,18.779297 0.364612,0.290384 0.881482,0.290384 1.246094,0 0,0 10.380882,-8.11056 10.380859,-18.779297 C 27.003893,5.9328244 22.059377,0.99609375 15.996094,0.99609375 Z m 0,6.00195315 c 2.749573,0 5.00585,2.2484784 5.005859,4.9980471 C 21.001971,14.7457 18.745685,17 15.996094,17 c -2.749591,0 -4.998064,-2.2543 -4.998047,-5.003906 9e-6,-2.7495687 2.248474,-4.9980471 4.998047,-4.9980471 z'
                      id='path929'
                      transform='matrix(0.26458333,0,0,0.26458333,0,288.53332)'
                    ></path>
                  </g>
                </g>
              </svg>
            </span>
            <input
              type='text'
              id='website-admin'
              className={addressClass}
              placeholder={
                formik.errors.address && formik.touched.address
                  ? formik.errors.address
                  : 'Address'
              }
              name='address'
              value={formik.values.address}
              onChange={formik.handleChange}
            />
          </div>
          <div className='flex mb-2'>
            <span className='inline-flex items-center px-3 text-sm text-gray-900 bg-gray-200 border border-r-0 border-gray-300 rounded-l-md'>
              <svg
                className='w-4 h-4'
                fill='currentColor'
                xmlns='http://www.w3.org/2000/svg'
                height='24'
                viewBox='0 -960 960 960'
                width='24'
              >
                <path d='M240-80q-50 0-85-35t-35-85v-120h120v-560l60 60 60-60 60 60 60-60 60 60 60-60 60 60 60-60 60 60 60-60v680q0 50-35 85t-85 35H240Zm480-80q17 0 28.5-11.5T760-200v-560H320v440h360v120q0 17 11.5 28.5T720-160ZM360-600v-80h240v80H360Zm0 120v-80h240v80H360Zm320-120q-17 0-28.5-11.5T640-640q0-17 11.5-28.5T680-680q17 0 28.5 11.5T720-640q0 17-11.5 28.5T680-600Zm0 120q-17 0-28.5-11.5T640-520q0-17 11.5-28.5T680-560q17 0 28.5 11.5T720-520q0 17-11.5 28.5T680-480ZM240-160h360v-80H200v40q0 17 11.5 28.5T240-160Zm-40 0v-80 80Z' />
              </svg>
            </span>
            <input
              type='text'
              id='website-admin'
              className={vatClass}
              placeholder={
                formik.errors.vat && formik.touched.vat
                  ? formik.errors.vat
                  : 'VAT'
              }
              name='vat'
              value={formik.values.vat}
              onChange={formik.handleChange}
            />
          </div>
          <div className='form-group'>
            <button
              disabled={loading}
              type='submit'
              className={`btn btn-default btn-block btn-sm bg-[#0084ff] disabled:!bg-gray-400 w-full ${
                formik.isValid ? '' : '!bg-gray-400 cursor-not-allowed'
              }`}
            >
              {!loading ? (
                <span>Save Account</span>
              ) : (
                <i className='fa fa-spin fa-spinner' />
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
