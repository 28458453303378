import BasicSupport from './BasicSupport';
import PremiumSupport from './PremiumSupport';
import Price from './Price';
import { FaCheck, FaBolt } from 'react-icons/fa';

const Prices = () => {
  return (
    <section
      className='prices m-t-80 p-b-80 container m-auto p-8 md:p-0'
      id='prices'
    >
      <div className=''>
        <p className='text-center text-2xl font-medium'>
          Our prices are scalable with respect to your monthly traffic.
        </p>
        <p className='text-center m-b-40'>
          You are not sure what your traffic would be? <br />
          Start with "Plus" and you can up/downgrade very easily later.
        </p>
      </div>
      <div className='pricesRow m-auto grid grid-cols-4 gap-4'>
        <Price
          className='col-span-4 sm:col-span-2 lg:col-span-1'
          plan='basic'
          features={
            <ul>
              <BasicSupport />
            </ul>
          }
        />

        <Price
          className='col-span-4 sm:col-span-2 lg:col-span-1'
          plan='plus'
          features={
            <ul>
              <BasicSupport />
            </ul>
          }
        />

        <Price
          className='col-span-4 sm:col-span-2 lg:col-span-1'
          plan='professional'
          features={
            <ul>
              <PremiumSupport />
              <li className='flex items-center gap-1'>
                <FaCheck /> <span>Customization</span>
              </li>
              <li className='flex items-center gap-1'>
                <FaCheck /> <span>24/7 Chat & Email</span>
              </li>
            </ul>
          }
        />

        <div className='m-t-20 col-span-4 sm:col-span-2 lg:col-span-1'>
          <div className='single-pricing-box'>
            <h3 className='text-2xl mt-[20px] mb-[10px] bg-[#6b30e9] p-[10px] text-uppercase font-medium text-white'>
              CORPORATE
            </h3>
            <div className='pricing-icon flex justify-center py-2'>
              <FaBolt />
            </div>
            <h4 className='border-b border-t border-solid border-[#000] py-[10px] text-xl my-[10px]'>
              &gt;&gt; 250k traffic
            </h4>
            <div className='pricing-list'>
              <ul className='text-center'>
                Please contact us for more information
              </ul>
            </div>
            <h3 className='pricing-count no-bg text-2xl mt-[20px] mb-[10px] bg-[#6b30e9] p-[10px] text-uppercase font-medium text-white'>
              &nbsp;
            </h3>
            <button
              className='bordered-btn'
              onClick={() => {
                console.log('opening $crisp chat', window.$crisp);
                window.$crisp.push(['do', 'chat:toggle']);
              }}
            >
              Contact Us
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Prices;
