import { FaXTwitter } from 'react-icons/fa6';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <footer className='row'>
      <div className='container m-auto'>
        <div className='row m0 social-links pb-0'>
          <ul className='nav'>
            <li className='' data-wow-delay='0.1s'>
              <Link
                target='_blank'
                to='https://x.com/utmsimple'
                className='flex justify-center items-center'
              >
                <FaXTwitter size={25} />
              </Link>
            </li>
          </ul>
        </div>
        <div className='row m0 menu-rights'>
          <ul className='nav footer-menu'>
            <li>
              <Link to='/terms'>Terms of Use</Link>
            </li>
            <li>
              <Link to='/privacy'>Privacy Policy</Link>
            </li>
            <li>
              <Link to='/disclaimer'>Disclaimer</Link>
            </li>
          </ul>
          <p className='footer-address'>
            10900 Research Blvd, Ste 160C, Austin, TX 78759&bull; +1 512 666
            4243
          </p>
          <p>
            Copyright &copy; {new Date().getFullYear()}. HandL Digital LLC{' '}
            <br className='small-divide' /> All rights reserved
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
