import { Link } from 'react-router-dom';
import { DownloadSVGIcon } from '../icons/download-icon';
import { LoadingSpinner } from '../icons/loading-spinner';
import { useAuthStore } from '../../../features/auth/AuthStore';
import { useMutation } from '@tanstack/react-query';
import { getCustomInvoiceURL } from './download-custom-invoice.api';

const downloadURI = (uri: string) => {
  if (uri) {
    const link = document.createElement('a');
    link.href = uri;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
};

const InvoiceRow = ({
  invoice,
  setReceiptUnavailableModal,
}: {
  invoice: any;
  setReceiptUnavailableModal: (o: boolean) => void;
}) => {
  const userAttributes = useAuthStore((state) => state.userAttributes);

  const hasCustomBillingInfo =
    userAttributes['custom:address'] &&
    userAttributes['custom:vat'] &&
    userAttributes['custom:name'] &&
    userAttributes['custom:company_name'] &&
    userAttributes['custom:name'];

  const customInvoiceMutation = useMutation({
    mutationFn: (data: { invoice_id: string; subscription_id: string }) => {
      return getCustomInvoiceURL({
        invoice_id: data.invoice_id,
        subscription_id: data.subscription_id,
      });
    },
    mutationKey: ['customInvoiceMutation'],
    onSuccess: (data) => {
      downloadURI(data.url);
    },
  });

  return (
    <tr key={invoice.id} className='bg-white border-b hover:bg-gray-50'>
      <th
        scope='row'
        className='px-6 py-4 font-medium text-gray-900 whitespace-nowrap'
      >
        {invoice.amount_paid?.toLocaleString('en-US', {
          style: 'currency',
          currency: 'USD',
        }) || 'N/A'}
      </th>
      <td className='px-6 py-4'>
        {new Date(invoice.created * 1000).toLocaleDateString()}
      </td>
      <td className='px-6 py-4'>
        {hasCustomBillingInfo && (
          <button
            onClick={() =>
              customInvoiceMutation.mutate({
                subscription_id: invoice.subscription,
                invoice_id: invoice.id,
              })
            }
            disabled={customInvoiceMutation.isPending}
          >
            {!customInvoiceMutation.isPending && <DownloadSVGIcon />}
            {customInvoiceMutation.isPending && (
              <LoadingSpinner className='w-6 h-6' />
            )}
          </button>
        )}

        {!hasCustomBillingInfo && invoice.invoice_pdf && (
          <Link to={invoice.invoice_pdf} target='_blank'>
            <DownloadSVGIcon />
          </Link>
        )}

        {!hasCustomBillingInfo && !invoice.invoice_pdf && (
          <button
            className='text-gray-300'
            onClick={() => setReceiptUnavailableModal(true)}
          >
            <DownloadSVGIcon />
          </button>
        )}
      </td>
    </tr>
  );
};

export { InvoiceRow };
