export default function Domain({
  domain,
  item_id,
  isActive,
  expandAccordianForPlanHandler,
}: Readonly<{
  domain: string;
  item_id: string;
  isActive: boolean;
  expandAccordianForPlanHandler: (item_id: string) => void;
}>) {
  return (
    <div className='rounded-md shadow-sm inline-block cursor-pointer'>
      <button
        className={`text-left w-[230px] p-2 truncate block rounded-md border-0 text-gray-900 ring-1 ring-inset ring-gray-300 sm:text-sm sm:leading-6 bg-gray-100 ${
          isActive ? 'cursor-pointer' : 'cursor-not-allowed'
        } `}
        style={{
          fontSize: '14px',
        }}
        disabled={!isActive}
        onClick={() => {
          expandAccordianForPlanHandler(item_id);
        }}
      >
        https://{domain}
      </button>
    </div>
  );
}
