import { FaInfoCircle, FaCheck } from 'react-icons/fa';
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/react';

const BasicSupport = () => {
  return (
    <li className='flex gap-1'>
      <div className='flex items-center gap-1'>
        <FaCheck /> <span>Basic Support</span>
      </div>{' '}
      <Popover className='inline'>
        <PopoverButton>
          <FaInfoCircle />
        </PopoverButton>
        <PopoverPanel className='absolute z-10 p-2 transition-opacity duration-300 bg-white border border-gray-200 rounded-lg shadow-sm'>
          <ul className='list-disc py-2 px-8'>
            <li>Initial response within two business days</li>{' '}
            <li>Basic installation/integration help via chat</li>
          </ul>
        </PopoverPanel>
      </Popover>
    </li>
  );
};

export default BasicSupport;
