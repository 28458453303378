import { getPrices, PlanType } from '../../../consts';
import cx from 'classnames';
import { useQuery } from '@tanstack/react-query';
import { getAccountInfo } from '../../AccountInfos/Subscriptions/account-info.api';
import { useAuthStore } from '../../../features/auth/AuthStore';
import { ReactNode } from 'react';
import { useSelectedPlanStore } from '../../../features/auth/SelectedPlanStore';
import { MINUTE } from '../../../consts/time';
import { FaBatteryQuarter, FaBatteryHalf, FaBatteryFull } from 'react-icons/fa';

const prices = getPrices();

const Price = ({
  plan,
  className,
  features,
}: {
  plan: PlanType;
  className: string;
  features: ReactNode;
}) => {
  const { data: accountInfo } = useQuery({
    queryKey: ['accountInfo'],
    queryFn: getAccountInfo,
    staleTime: 10 * MINUTE,
  });

  const haveAlreadyBought = accountInfo?.subscriptions?.length > 0;

  const setSelectedPlan = useSelectedPlanStore(
    (state) => state.setSelectedPlan
  );

  const showPaymentModal = useAuthStore((state) => state.showPaymentModal);

  const buyPlanHandler = (plan: PlanType) => {
    console.log(plan);

    const price = prices[plan];
    const payload = {
      selected_plan: plan,
      product_price: price['product_price'],
      original_price: price['original_price'],
      paypal_plan_id: price['paypal'],
      stripe_plan_id: price['stripe'],
      msrp: price['msrp'],
      membership: price['membership'],
      title: price['title'],
      subtitle: price['subtitle'],
    };

    setSelectedPlan!({
      ...payload,
    });

    showPaymentModal();
  };

  const price = prices[plan];
  return (
    <div className={cx('m-t-20', className)}>
      <div className='single-pricing-box'>
        <h3 className='text-2xl mt-[20px] mb-[10px] bg-[#6b30e9] p-[10px] text-uppercase font-medium text-white'>
          {price['title']}
        </h3>
        <div className='pricing-icon flex justify-center py-1'>
          {price['icon'] === 'battery-quarter' && (
            <FaBatteryQuarter size={32} />
          )}

          {price['icon'] === 'battery-half' && <FaBatteryHalf size={32} />}
          {price['icon'] === 'battery-full' && <FaBatteryFull size={32} />}
        </div>
        <p className='border-b border-t border-solid border-[#000] py-[10px] text-xl my-[10px]'>
          {price.traffic}
        </p>
        <div className='pricing-list'>{features}</div>
        <h3 className='pricing-count text-2xl mt-[20px] mb-[10px] bg-[#6b30e9] p-[10px] text-uppercase font-medium text-white'>
          ${price['product_price']}/mo
        </h3>
        <button className='bordered-btn' onClick={() => buyPlanHandler(plan)}>
          {haveAlreadyBought && <>Get Another License</>}
          {!haveAlreadyBought && <>Start Free Trial</>}
        </button>
      </div>
    </div>
  );
};

export default Price;
