import { Component } from 'react';

declare global {
  interface Window {
    $crisp: any;
  }
}

class Contact extends Component {
  render() {
    return (
      <section className='container mx-auto my-20 text-center' id='contact'>
        <p className='text-lg m-auto'>
          Need some help? Or an integration question?{' '}
          <button
            onClick={() => {
              console.log('opening $crisp chat', window.$crisp);
              window.$crisp?.push(['do', 'chat:toggle']);
            }}
            className='btn btn-xs btn-primary py-2 cursor-pointer'
          >
            Chat Now
          </button>
        </p>
      </section>
    );
  }
}

export default Contact;
