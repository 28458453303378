type PricePlan = {
  paypal: string;
  stripe: string;
  product_price: number;
  original_price: number;
  msrp: number;
  membership: boolean;
  terms: string;
  title: string;
  traffic: string;
  icon: 'battery-quarter' | 'battery-half' | 'battery-full';
  subtitle?: string;
};

export function getPrices(): Record<
  'basic' | 'plus' | 'professional',
  PricePlan
> {
  return {
    basic: {
      paypal:
        process.env.REACT_APP_ENV_MODE === 'production'
          ? 'P-2MF95308E09061601MFAYCRA'
          : 'P-1VV23228KU635671CME2COPY',
      stripe:
        process.env.REACT_APP_ENV_MODE === 'production'
          ? 'price_1IW4HRAazLOJAQUCmwoqq6l0'
          : 'price_1JVk7qAazLOJAQUCteve124Z',
      product_price: 10,
      original_price: 10,
      msrp: 20,
      membership: true,
      terms: 'month',
      title: 'Basic',
      traffic: 'Up to 5k traffic',
      icon: 'battery-quarter',
    },
    plus: {
      paypal:
        process.env.REACT_APP_ENV_MODE === 'production'
          ? 'P-92431027EF4583530MFAYC5Q'
          : 'P-4WR9930560038681MME2CPAY',
      stripe:
        process.env.REACT_APP_ENV_MODE === 'production'
          ? 'price_1JQlclAazLOJAQUCi6TVYEWm'
          : 'price_1JVk87AazLOJAQUCvlRfbpev',
      product_price: 25,
      original_price: 25,
      msrp: 75,
      membership: true,
      terms: 'month',
      title: 'Plus',
      traffic: 'Up to 25k traffic',
      icon: 'battery-half',
    },
    professional: {
      paypal:
        process.env.REACT_APP_ENV_MODE === 'production'
          ? 'P-0YK50658SU292205HMFAYDGA'
          : 'P-7HD917391P198121LME2CPJQ',
      stripe:
        process.env.REACT_APP_ENV_MODE === 'production'
          ? 'price_1JZqOjAazLOJAQUC96I8GTgp'
          : 'price_1JVj9YAazLOJAQUCKeLz0s8o',
      product_price: 99,
      original_price: 99,
      msrp: 199,
      membership: true,
      terms: 'month',
      title: 'Professional',
      traffic: 'Up to 250k traffic',
      icon: 'battery-full',
    },
  };
}

export type PlanType = keyof ReturnType<typeof getPrices>;
