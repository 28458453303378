import { NavLink } from 'react-router-dom';

function AccountInfoSidebar() {
  return (
    <aside className='static left-0 z-40 w-64 min-h-[calc(100vh-240px)] h-full'>
      <div className='h-full px-3 py-4 overflow-y-auto bg-gray-50'>
        <ul className='space-y-2 font-medium'>
          <li>
            <NavLink
              to='/infos/subscriptions'
              className={({ isActive }) =>
                `flex items-center p-2 text-gray-900 rounded-lg hover:bg-gray-100 cursor-pointer ${
                  isActive ? 'bg-gray-200' : ''
                }`
              }
            >
              <svg
                className='flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75 group-hover:text-gray-900'
                aria-hidden='true'
                viewBox='0 0 24 24'
                xmlns='http://www.w3.org/2000/svg'
                fill='#000000'
              >
                <g id='SVGRepo_bgCarrier' strokeWidth='0'></g>
                <g
                  id='SVGRepo_tracerCarrier'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                ></g>
                <g id='SVGRepo_iconCarrier'>
                  <path
                    fill='#000000'
                    d='M12 0a2 2 0 0 0-1.81 1.15L7.556 6.758 1.7 7.642a2 2 0 0 0-1.13 3.374l4.3 4.408-1.02 6.235a2 2 0 0 0 2.94 2.073L12 20.86l5.216 2.89a2 2 0 0 0 2.942-2.073l-1.02-6.237 4.293-4.39a2 2 0 0 0-1.124-3.376l-5.857-.9-2.64-5.624A2 2 0 0 0 12 0z'
                  ></path>
                </g>
              </svg>
              <span className='flex-1 ml-3 whitespace-nowrap'>
                Subscriptions
              </span>
            </NavLink>
          </li>
          <li>
            <NavLink
              to='/infos/billings'
              className={({ isActive }) =>
                `flex items-center p-2 text-gray-900 rounded-lg hover:bg-gray-100 cursor-pointer ${
                  isActive ? 'bg-gray-200' : ''
                }`
              }
            >
              <svg
                className='flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75 group-hover:text-gray-900'
                fill='#000000'
                viewBox='0 0 512 512'
                xmlns='http://www.w3.org/2000/svg'
              >
                <g id='SVGRepo_bgCarrier' strokeWidth='0'></g>
                <g
                  id='SVGRepo_tracerCarrier'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                ></g>
                <g id='SVGRepo_iconCarrier'>
                  <title>ionicons-v5-d</title>
                  <path d='M32,416a16,16,0,0,0,16,16H464a16,16,0,0,0,16-16V222H32ZM98,278a8,8,0,0,1,8-8h92a8,8,0,0,1,8,8v64a8,8,0,0,1-8,8H106a8,8,0,0,1-8-8Z'></path>
                  <path d='M464,80H48A16,16,0,0,0,32,96v66H480V96A16,16,0,0,0,464,80Z'></path>
                </g>
              </svg>
              <span className='flex-1 ml-3 whitespace-nowrap'>Billings</span>
            </NavLink>
          </li>
          <li>
            <NavLink
              to='/infos/myaccounts'
              className={({ isActive }) =>
                `flex items-center p-2 text-gray-900 rounded-lg hover:bg-gray-100 cursor-pointer ${
                  isActive ? 'bg-gray-200' : ''
                }`
              }
            >
              <svg
                className='flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75 group-hover:text-gray-900'
                viewBox='0 0 24 24'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <g id='SVGRepo_bgCarrier' strokeWidth='0'></g>
                <g
                  id='SVGRepo_tracerCarrier'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                ></g>
                <g id='SVGRepo_iconCarrier'>
                  <path
                    d='M20.1 9.2214C18.29 9.2214 17.55 7.9414 18.45 6.3714C18.97 5.4614 18.66 4.3014 17.75 3.7814L16.02 2.7914C15.23 2.3214 14.21 2.6014 13.74 3.3914L13.63 3.5814C12.73 5.1514 11.25 5.1514 10.34 3.5814L10.23 3.3914C9.78 2.6014 8.76 2.3214 7.97 2.7914L6.24 3.7814C5.33 4.3014 5.02 5.4714 5.54 6.3814C6.45 7.9414 5.71 9.2214 3.9 9.2214C2.86 9.2214 2 10.0714 2 11.1214V12.8814C2 13.9214 2.85 14.7814 3.9 14.7814C5.71 14.7814 6.45 16.0614 5.54 17.6314C5.02 18.5414 5.33 19.7014 6.24 20.2214L7.97 21.2114C8.76 21.6814 9.78 21.4014 10.25 20.6114L10.36 20.4214C11.26 18.8514 12.74 18.8514 13.65 20.4214L13.76 20.6114C14.23 21.4014 15.25 21.6814 16.04 21.2114L17.77 20.2214C18.68 19.7014 18.99 18.5314 18.47 17.6314C17.56 16.0614 18.3 14.7814 20.11 14.7814C21.15 14.7814 22.01 13.9314 22.01 12.8814V11.1214C22 10.0814 21.15 9.2214 20.1 9.2214ZM12 15.2514C10.21 15.2514 8.75 13.7914 8.75 12.0014C8.75 10.2114 10.21 8.7514 12 8.7514C13.79 8.7514 15.25 10.2114 15.25 12.0014C15.25 13.7914 13.79 15.2514 12 15.2514Z'
                    fill='#292D32'
                  ></path>
                </g>
              </svg>
              <span className='flex-1 ml-3 whitespace-nowrap'>My Account</span>
            </NavLink>
          </li>
        </ul>
      </div>
    </aside>
  );
}

export default AccountInfoSidebar;
