import { Link } from 'react-router-dom';
import Banner from './Banner';

export default function Hero() {
  return (
    <section className='bg-transparent relative'>
      <svg
        id='banner-header-svg'
        className='separator__svg w-full h-full'
        viewBox='0 0 100 100'
        preserveAspectRatio='none'
        fill='#6b30e9'
        version='1.1'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path d='M 100 100 V 10 L 0 100' />
        <path d='M 30 73 L 100 18 V 10 Z' fill='#6b30e9' strokeWidth='0' />
      </svg>
      <div className='top-banner pt-60 text-center fadeInOnLoad grid grid-cols-12 px-4 md:pl-20'>
        <div className='md:col-span-5 col-span-12'>
          <h2>The simplest lead tracker that works!</h2>
          <p>
            Sign-up free and start tracking in seconds. <br /> No integration,
            no development, no headache. <br />
            Yes tracking leads!
          </p>
          <Link
            to='/#features'
            className='bg-[#6b30e9] px-8 py-3 my-3 size-lg rounded-3xl
            text-white font-medium cursor-pointer'
          >
            <i className='fa fa-cloud-download'></i> TRY FOR FREE
          </Link>
          <br />
        </div>
        <Banner />
      </div>
    </section>
  );
}
