import { AuthUser, FetchUserAttributesOutput } from 'aws-amplify/auth';
import { create } from 'zustand';

type AuthStore = {
  isLoginFormVisible: boolean;
  showLoginForm: () => void;
  hideLoginForm: () => void;

  isForgotPasswordFormVisible: boolean;
  showForgotPasswordForm: () => void;
  hideForgotPasswordForm: () => void;

  isThankYouModalVisible: boolean;
  showThankYouModal: () => void;
  hideThankYouModal: () => void;

  isPaymentModalVisible: boolean;
  showPaymentModal: () => void;
  hidePaymentModal: () => void;

  userAttributes: FetchUserAttributesOutput;
  setUserAttributes: (attributes: FetchUserAttributesOutput) => void;

  isAuthenticated: boolean;
  makeUserAuthenticated: () => void;
  makeIsAuthenticatedFalse: () => void;

  user: AuthUser | null;
  setAuthUser: (user: AuthUser) => void;
  removeAuthUser: () => void;

  pendingUser: PendingUser | null;
  setPendingUser: (pendingUser: PendingUser) => void;
  removePendingUser: () => void;
};

const useAuthStore = create<AuthStore>((set) => ({
  isLoginFormVisible: false,
  showLoginForm: () => {
    set(() => ({ isLoginFormVisible: true }));
  },
  hideLoginForm: () => {
    set(() => ({ isLoginFormVisible: false }));
  },

  isForgotPasswordFormVisible: false,
  showForgotPasswordForm: () => {
    set(() => ({ isForgotPasswordFormVisible: true }));
  },
  hideForgotPasswordForm: () => {
    set(() => ({ isForgotPasswordFormVisible: false }));
  },

  isThankYouModalVisible: false,
  showThankYouModal: () => {
    set(() => ({ isThankYouModalVisible: true }));
  },
  hideThankYouModal: () => {
    set(() => ({ isThankYouModalVisible: false }));
  },

  userAttributes: {},
  setUserAttributes: (attributes) => {
    set(() => ({ userAttributes: { ...attributes } }));
  },

  isPaymentModalVisible: false,
  showPaymentModal: () => {
    set(() => ({ isPaymentModalVisible: true }));
  },
  hidePaymentModal: () => {
    set(() => ({ isPaymentModalVisible: false }));
  },

  isAuthenticated: false,
  makeUserAuthenticated: () => {
    set(() => ({ isAuthenticated: true }));
  },
  makeIsAuthenticatedFalse: () => {
    set(() => ({ isAuthenticated: false }));
  },

  user: null,
  setAuthUser: (_user) => {
    set(() => ({ user: { ..._user } }));
  },
  removeAuthUser: () => {
    set(() => ({ user: null }));
  },

  pendingUser: null,
  setPendingUser: (pendingUser: PendingUser) => {
    set(() => ({ pendingUser: { ...pendingUser } }));
  },
  removePendingUser: () => {
    set(() => ({ pendingUser: null }));
  },
}));

type PendingUser = {
  email?: string;
  first_name?: string;
};

export { useAuthStore };
