import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
} from '@headlessui/react';
import { HiChevronUp } from 'react-icons/hi';

const faqs = [
  {
    q: "Is it really free? What's the catch?",
    a: 'There is no catch. You can try it for FREE. After the free trial, you will be charged monthly.',
  },
  {
    q: 'Why buy from you? What makes you so special?',
    a: 'We are the first-ever published UTM plugin for WordPress, backed by over 9 years of experience in tracking and capturing UTM parameters. You can count on us.',
  },
  {
    q: 'What data can I collect?',
    a: (
      <>
        You can collect all the UTMs, including utm_campaign, utm_source,
        utm_medium, and more. Additionally, you can capture referrer URLs,
        organic sources, _fbc, _fbq, IP addresses, user agents, fbclid, and
        gclid parameters. See the full list of parameters{' '}
        <a
          className='text-blue-500 hover:text-blue-700'
          href='https://docs.utmsimple.com/books/101-lets-start/page/what-can-i-track-with-utmsimple'
          target='_blank'
        >
          here
        </a>
      </>
    ),
  },
  {
    q: 'Are you GDPR compliant?',
    a: 'Our plugin is fully GDPR compliant.',
  },
  {
    q: 'Is this tool secure?',
    a: 'You can use the tool with confidence. We never store any personally identifiable information (PII), and the plugin never sends any user-related data to our servers. All the data is recorded on a first-party cookie, so you own the data.',
  },
  {
    q: 'I have paid ads campaigns (ppc), can I use this plugin to track which campaign my leads come from?',
    a: 'Yes, you can effortlessly track gclid and fbclid and connect user data with your paid campaigns.',
  },
  {
    q: 'Can I see what my leads search before they land on my site via Google Ads',
    a: 'Yes, you can append the {keyword} ValueTrack parameter to your final URLs and track it within UTM Simple.',
  },
  {
    q: 'How UTMs stored? Do they need to be in the URL all the time for tracking?',
    a: 'UTMs are stored in cookies (on the client’s browser) as soon as they appear in the URL. This means your visitors continue to be tracked even if the UTMs are no longer in the query string. When a user converts (through lead generation or a sale), the UTMs will be captured and linked to the user.',
  },
  {
    q: 'Can I track users across subdomains?',
    a: (
      <>
        Yes, UTM parameters and other custom variables are recorded at the
        highest domain level. For example, if your landing page is on{' '}
        <code className='code'>example.com</code> and users convert to{' '}
        <code className='code'>sub.example.com</code>, Handl Tracker will still
        capture the cookies from <code className='code'>example.com</code>.
      </>
    ),
  },
  {
    q: 'Do you provide support if I need help getting the plugin work?',
    a: 'We are here for you whenever you need us. We are more than happy to help you integrate and start collecting the data you need in no time.',
  },
  {
    q: 'Are you impacted by cookieless browsers or any of the privacy-related updates in browsers?',
    a: 'No, our plugin stores data in first-party cookies, which means we are not impacted by any privacy-related updates. Our plugin will continue working on cookieless browsers as well.',
  },
];

const Faq = () => {
  return (
    <section className='m-auto container' id='faq'>
      <h1 className='text-center text-4xl'>Still Got Questions?</h1>
      <div className='w-full px-4 pt-4'>
        <div className='mx-auto w-full rounded-2xl bg-white p-2'>
          {faqs.map((faq) => (
            <Disclosure key={faq.q}>
              {({ open }) => (
                <div className='border-gray-300 border-[1px] mt-2 rounded-lg border-solid'>
                  <DisclosureButton className='flex w-full justify-between rounded-lg bg-gray-100 px-4 py-2 text-left text-lg font-medium hover:bg-gray-200 focus:outline-none focus-visible:ring focus-visible:ring-gray-500/75'>
                    <span>{faq.q}</span>
                    <HiChevronUp
                      size={20}
                      className={`${
                        open ? 'rotate-180 transform' : ''
                      } h-5 w-5 text-gray-500 my-auto`}
                    />
                  </DisclosureButton>
                  <DisclosurePanel className='px-4 pb-2 pt-4 text-md text-gray-500'>
                    {faq.a}
                  </DisclosurePanel>
                </div>
              )}
            </Disclosure>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Faq;
