import { fetchAuthSession } from 'aws-amplify/auth';
import { joinUrls } from './join-url';

type Invoice = {
  id: string;
  amount_paid: number;
  created: number;
  subscription: string;
};

const getInvoiceListYearWise = async (): Promise<{
  [year: number]: Invoice[];
}> => {
  const token = (await fetchAuthSession()).tokens?.idToken?.toString();

  const response = await fetch(
    joinUrls(process.env.REACT_APP_API_ENDPOINT!, 'http/invoice/list'),
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  const data = response.json();

  return data;
};

export { getInvoiceListYearWise };
