import { useEffect, useState } from 'react';
import { useLocation, useNavigate, Outlet } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorScreen from '../Error/ErrorScreen';
import AccountInfoSidebar from './AccountInfoSidebar';
import Drawer from '../Drawer';
import { useAuthStore } from '../../features/auth/AuthStore';
import { toast } from 'react-toastify';

export default function AccountInfos() {
  const navigate = useNavigate();
  const location = useLocation();

  const isAuthenticated = useAuthStore((state) => state.isAuthenticated);
  const pendingUser = useAuthStore((state) => state.pendingUser);

  const user = useAuthStore((state) => state.user);
  const emailNotVerified = !user && pendingUser;
  const [accountInfosDrawerVisible, setAccountInfosDrawerVisible] =
    useState(false);

  useEffect(() => {
    if (!isAuthenticated && pendingUser) {
      toast.error('Please confirm your email!!!');
      navigate('/');
    }
  }, [pendingUser]);

  return (
    <div className='md:flex mt-20'>
      <Drawer
        className={'md:hidden'}
        isOpen={accountInfosDrawerVisible}
        onClose={() => setAccountInfosDrawerVisible(false)}
        onAfterClosed={() => {}}
        onAfterOpen={() => {}}
        onOpen={() => {}}
        showCloseFooter={false}
        title={''}
      >
        <AccountInfoSidebar />
      </Drawer>
      <button
        type='button'
        onClick={() => setAccountInfosDrawerVisible(true)}
        className='inline-flex items-center p-2 mt-2 ml-3 text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200'
      >
        <span className='sr-only'>Open sidebar</span>
        <svg
          className='w-6 h-6'
          aria-hidden='true'
          fill='currentColor'
          viewBox='0 0 20 20'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            clipRule='evenodd'
            fillRule='evenodd'
            d='M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z'
          ></path>
        </svg>
      </button>
      {/*Pending user means the user just registered but didn't verify their email pendingUser && !user && */}
      <div className='flex flex-row'>
        <div className='hidden md:block'>
          <AccountInfoSidebar />
        </div>
      </div>

      <div className='static w-full p-2 md:p-5 lg:p-10'>
        <ErrorBoundary FallbackComponent={ErrorScreen} key={location.pathname}>
          {emailNotVerified ? (
            <div>
              <div className='px-6 py-4 text-center'>
                <div className='fa-3x'>
                  <p>
                    Please go to your email and validate your email before
                    continuing.
                  </p>
                </div>
              </div>
            </div>
          ) : (
            <Outlet />
          )}
        </ErrorBoundary>
      </div>
    </div>
  );
}
