import { useState, useEffect, useRef, FormEvent } from 'react';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';
import SimpleReactValidator from 'simple-react-validator';
import $ from 'jquery';
import { useAuthStore } from '../features/auth/AuthStore';
import { useSelectedPlanStore } from '../features/auth/SelectedPlanStore';
import { useQueryClient } from '@tanstack/react-query';

const { gtag, HandL } = window;

export default function StripeCheckoutForm({
  ccSelected,
}: Readonly<{ ccSelected: boolean }>) {
  const [succeeded, setSucceeded] = useState(false);
  const [error, setError] = useState(null);
  const [processing, setProcessing] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [clientSecret, setClientSecret] = useState('');
  const [address, setAddress] = useState('');
  const [country, setCountry] = useState('US');
  const [region, setRegion] = useState('');
  const [postal, setPostal] = useState('');
  const [customer, setCustomer] = useState(null);
  const [ccOnFile, setccOnFile] = useState(false);
  const stripe = useStripe();
  const elements = useElements();
  const [_, forceUpdate] = useState(0);
  const validator = useRef(
    new SimpleReactValidator({
      autoForceUpdate: { forceUpdate: forceUpdate },
      element: (message: string, className: string) => (
        <div className={className}>{message}</div>
      ),
    })
  );

  const hidePaymentModal = useAuthStore((state) => state.hidePaymentModal);
  const showThankYouModal = useAuthStore((state) => state.showThankYouModal);
  const pendingUser = useAuthStore((state) => state.pendingUser);

  const selected_plan = useSelectedPlanStore((state) => state.selected_plan);
  const title = useSelectedPlanStore((state) => state.title);
  const quantity = useSelectedPlanStore((state) => state.quantity);
  const product_price = useSelectedPlanStore((state) => state.product_price);
  const membership = useSelectedPlanStore((state) => state.membership);
  const stripe_plan_id = useSelectedPlanStore((state) => state.stripe_plan_id);
  const multiplier = useSelectedPlanStore((state) => state.multiplier);
  const queryClient = useQueryClient();

  useEffect(() => {
    // Create PaymentIntent as soon as the page loads
    if (ccSelected) {
      window
        .fetch(process.env.REACT_APP_API_ENDPOINT + 'http/geo', {
          method: 'GET',
        })
        .then(function (response) {
          if (!response.ok) {
            throw Error(response.statusText);
          }
          return response;
        })
        .then((res) => {
          return res.json();
        })
        .then((data) => {
          setRegion(data.region);
          setCountry(data.countryCode);
          setPostal(data.zip);
        });

      $('#payment-form').find('input')[0].focus();

      if (membership) {
        createCustomer(0);
      } else {
        getClientSecret(0);
      }
    }
  }, [ccSelected]);

  function beginCheckoutGA() {
    gtag('event', 'begin_checkout', {
      items: [
        {
          id: selected_plan,
          name: 'UTMSimple Tracker',
          brand: 'UTMSimple',
          variant: title,
          list_position: 2,
          quantity: `${quantity}`,
          price: `${product_price}.00`,
        },
      ],
      email: pendingUser?.email,
    });
  }

  const getClientSecret = async (tries: number) => {
    if (tries <= 3) {
      await window
        .fetch(process.env.REACT_APP_API_ENDPOINT + 'http/charge', {
          method: 'POST',
          // headers: auth.getCognitoHeaders(),
          body: JSON.stringify({
            pay_id: 'stripe',
            quantity: quantity,
          }),
        })
        .then(function (response) {
          if (!response.ok) {
            setTimeout(function () {
              getClientSecret(tries + 1);
            }, 500);
            throw Error(response.statusText);
          }
          return response;
        })
        .then((res) => {
          return res.json();
        })
        .then((data) => {
          setClientSecret(data.clientSecret.client_secret);
          beginCheckoutGA();
        })
        .catch(function () {
          setTimeout(function () {
            getClientSecret(tries + 1);
          }, 500);
        });
    }
  };

  const createCustomer = (tries: number) => {
    if (tries <= 3) {
      return fetch(process.env.REACT_APP_API_ENDPOINT + 'http/charge', {
        method: 'post',
        // headers: auth.getCognitoHeaders(),
        body: JSON.stringify({
          pay_id: 'stripe',
          membership: true,
          type: 'cus',
          email: pendingUser?.email,
          name: pendingUser?.first_name,
          marketing: HandL?.getAll(),
        }),
      })
        .then((response) => {
          return response.json();
        })
        .then((result) => {
          setCustomer(result.customer);
          beginCheckoutGA();
        })
        .catch(function () {
          setTimeout(function () {
            createCustomer(tries + 1);
          }, 500);
        });
    }
  };

  const cardStyle = {
    style: {
      base: {
        fontFamily: '"Poppins",sans-serif',
        fontSmoothing: 'antialiased',
        fontSize: '17px',
      },
      invalid: {
        color: '#fa755a',
        iconColor: '#fa755a',
      },
    },
  };

  function createSubscription({
    customerId,
    paymentMethodId,
    quantity,
  }: {
    customerId: string;
    paymentMethodId: string;
    quantity: number;
  }) {
    return (
      window
        .fetch(process.env.REACT_APP_API_ENDPOINT + 'http/charge', {
          method: 'post',
          // headers: auth.getCognitoHeaders(),
          body: JSON.stringify({
            pay_id: 'stripe',
            membership: true,
            type: 'sub',
            customerId: customerId,
            paymentMethodId: paymentMethodId,
            quantity: quantity,
            priceId: stripe_plan_id,
            multiplier: multiplier ? 2 : 1,
            marketing: HandL?.getAll(),
          }),
        })
        .then((response) => {
          return response.json();
        })
        // If the card is declined, display an error to the user.
        .then((result) => {
          if (result.error) {
            // The card had an error when trying to attach it to a customer.
            throw result;
          }
          return result;
        })
        // Normalize the result to contain the object returned by Stripe.
        // Add the additional details we need.
        .then((result) => {
          return {
            paymentMethodId: paymentMethodId,
            quantity: quantity,
            subscription: result,
            subscription_status: result.status,
          };
        })
        // Some payment methods require a customer to be on session
        // to complete the payment process. Check the status of the
        // payment intent to handle these actions.
        .then(handlePaymentThatRequiresCustomerAction as any)
        // If attaching this card to a Customer object succeeds,
        // but attempts to charge the customer fail, you
        // get a requires_payment_method error.
        .then(handleRequiresPaymentMethod)
        // No more actions required. Provision your service for the user.
        .then(onSubscriptionComplete)
        .catch((error) => {
          // An error has happened. Display the failure to the user here.
          // We utilize the HTML element we created.
          setProcessing(false);
          handleChange(error);
        })
    );
  }

  function handlePaymentThatRequiresCustomerAction({
    subscription,
    invoice,
    quantity,
    paymentMethodId,
    isRetry,
    subscription_status,
  }: {
    subscription: any;
    invoice: any;
    quantity: number;
    paymentMethodId: string;
    isRetry: boolean;
    subscription_status: any;
  }) {
    if (
      subscription &&
      (subscription.status === 'active' || subscription.status === 'trialing')
    ) {
      // Subscription is active, no customer actions required.
      return { subscription, quantity, paymentMethodId, subscription_status };
    }

    // If it's a first payment attempt, the payment intent is on the subscription latest invoice.
    // If it's a retry, the payment intent will be on the invoice itself.
    let paymentIntent = invoice
      ? invoice.payment_intent
      : subscription.latest_invoice.payment_intent;

    if (
      paymentIntent.status === 'requires_action' ||
      (isRetry === true && paymentIntent.status === 'requires_payment_method')
    ) {
      return stripe!
        .confirmCardPayment(paymentIntent.client_secret, {
          payment_method: paymentMethodId,
        })
        .then((result) => {
          if (result.error) {
            // Start code flow to handle updating the payment details.
            // Display error message in your UI.
            // The card was declined (i.e. insufficient funds, card has expired, etc).
            throw result;
          } else {
            if (result.paymentIntent.status === 'succeeded') {
              // Show a success message to your customer.
              // There's a risk of the customer closing the window before the callback.
              // We recommend setting up webhook endpoints later in this guide.
              return {
                subscription_status: 'active',
                quantity: quantity,
                subscription: subscription,
                invoice: invoice,
                paymentMethodId: paymentMethodId,
              };
            }
          }
        })
        .catch((error) => {
          throw error;
        });
    } else {
      // No customer action needed.
      return { subscription, quantity, paymentMethodId, subscription_status };
    }
  }

  function handleRequiresPaymentMethod({
    subscription,
    paymentMethodId,
    quantity,
    subscription_status,
  }: {
    subscription: any;
    paymentMethodId: string;
    quantity: number;
    subscription_status: any;
  }) {
    if (
      subscription.status === 'active' ||
      subscription.status === 'trialing'
    ) {
      // subscription is active, no customer actions required.
      return { subscription, quantity, paymentMethodId, subscription_status };
    } else if (
      subscription.latest_invoice.payment_intent.status ===
      'requires_payment_method'
    ) {
      // Using localStorage to manage the state of the retry here,
      // feel free to replace with what you prefer.
      // Store the latest invoice ID and status.
      // localStorage.setItem('latestInvoiceId', subscription.latest_invoice.id);
      // localStorage.setItem(
      //     'latestInvoicePaymentIntentStatus',
      //     subscription.latest_invoice.payment_intent.status
      // );
      throw { error: { message: 'Your card was declined.' } };
    } else {
      return { subscription, quantity, paymentMethodId, subscription_status };
    }
  }

  function onSubscriptionComplete(result: any) {
    // Payment was successful.
    if (
      result.subscription_status === 'active' ||
      result.subscription_status === 'trialing'
    ) {
      // Change your UI to show a success message to your customer.
      // Call your backend to grant access to your service based on
      // `result.subscription.items.data[0].price.product` the customer subscribed to.
      paymentSuccessful(result);

      console.log('query invalidated....');
      queryClient.invalidateQueries({
        queryKey: ['accountInfo'],
      });
    }
  }

  function paymentSuccessful(payload: any) {
    setError(null);
    setProcessing(false);
    setSucceeded(true);
    hidePaymentModal();
    showThankYouModal();

    let amount, txid;

    if (payload.paymentIntent !== undefined) {
      amount = payload.paymentIntent.amount;
      txid = payload.paymentIntent.id;
    } else if (payload.subscription !== undefined) {
      amount = payload.subscription.latest_invoice.amount_paid;
      txid = payload.subscription.latest_invoice.charge;
    } else {
      //hope never goes here :)
      amount = 1;
      txid = 'Not Captured';
    }

    gtag('event', 'purchase', {
      transaction_id: txid,
      value: amount / 100,
      currency: 'USD',
      tax: 0.0,
      shipping: 0,
      items: [
        {
          id: selected_plan,
          name: 'UTMSimple Tracker',
          brand: 'UTMSimple',
          variant: title,
          list_position: 2,
          quantity: quantity,
          price: amount / 100,
        },
      ],
      email: pendingUser?.email,
    });

    gtag('event', 'conversion', {
      send_to: 'AW-604754791/ySYVCOmm8NoBEOemr6AC',
      transaction_id: txid,
      value: amount / 100,
      currency: 'USD',
    });
  }

  const retryInvoiceWithNewPaymentMethod = async ({
    customerId,
    paymentMethodId,
    invoiceId,
    quantity,
  }: {
    customerId: string;
    paymentMethodId: string;
    invoiceId: string;
    quantity: number;
  }) => {
    return (
      fetch(process.env.REACT_APP_API_ENDPOINT + 'http/charge', {
        method: 'post',
        // headers: auth.getCognitoHeaders(),
        body: JSON.stringify({
          pay_id: 'stripe',
          membership: true,
          type: 'retry',
          customerId: customerId,
          paymentMethodId: paymentMethodId,
          invoiceid: invoiceId,
          quantity: quantity,
          marketing: HandL?.getAll(),
        }),
      })
        .then((response) => {
          return response.json();
        })
        // If the card is declined, display an error to the user.
        .then((result) => {
          if (result.error) {
            // The card had an error when trying to attach it to a customer.
            throw result;
          }
          return result;
        })
        // Normalize the result to contain the object returned by Stripe.
        // Add the additional details we need.
        .then((result) => {
          return {
            // Use the Stripe 'object' property on the
            // returned result to understand what object is returned.
            invoice: result,
            paymentMethodId: paymentMethodId,
            isRetry: true,
            quantity: quantity,
            subscription_status: result.status,
          };
        })
        // Some payment methods require a customer to be on session
        // to complete the payment process. Check the status of the
        // payment intent to handle these actions.
        .then(handlePaymentThatRequiresCustomerAction as any)
        // No more actions required. Provision your service for the user.
        .then(onSubscriptionComplete)
        .catch((error) => {
          // An error has happened. Display the failure to the user here.
          // We utilize the HTML element we created.
          setProcessing(false);
          handleChange(error);
        })
    );
  };

  const handleChange = async (event: any) => {
    // Listen for changes in the CardElement
    // and display any errors as the customer types their card details
    setDisabled(event.empty);
    setError(event.error ? event.error.message : '');
  };

  const handleSubmit = async (ev: FormEvent) => {
    ev.preventDefault();

    if (validator.current.allValid() || customer) {
      setProcessing(true);

      let payload: any, latestInvoicePaymentIntentStatus: any;
      if (!ccOnFile) {
        const cardElement = elements!.getElement(CardElement);

        if (membership) {
          latestInvoicePaymentIntentStatus = localStorage.getItem(
            'latestInvoicePaymentIntentStatus'
          );

          payload = await stripe!.createPaymentMethod({
            type: 'card',
            card: cardElement,
            billing_details: {
              name: pendingUser?.first_name,
              email: pendingUser?.email,
              address: {
                line1: address,
                postal_code: postal,
                state: region,
                country: country,
              },
            },
          } as any);
        } else {
          payload = await stripe!.confirmCardPayment(clientSecret, {
            payment_method: {
              card: cardElement!,
              billing_details: {
                name: pendingUser?.first_name,
                email: pendingUser?.email,
                address: {
                  line1: address,
                  postal_code: postal,
                  state: region,
                  country: country,
                },
              },
            },
          });
        }
      }

      if (!ccOnFile && payload!.error) {
        setError(`Payment failed ${payload?.error.message}` as any);
        setProcessing(false);
      } else {
        if (membership) {
          const paymentMethodId = payload?.paymentMethod.id;
          const customerId = (customer as any)?.id;

          if (!quantity) return;

          if (latestInvoicePaymentIntentStatus === 'requires_payment_method') {
            // Update the payment method and retry invoice payment
            const invoiceId = localStorage.getItem('latestInvoiceId')!;
            retryInvoiceWithNewPaymentMethod({
              customerId,
              paymentMethodId,
              invoiceId,
              quantity,
            });
          } else {
            // Create the subscription
            createSubscription({ customerId, paymentMethodId, quantity });
          }
        } else {
          paymentSuccessful(payload);
        }
      }
    } else {
      validator.current.showMessages();
      forceUpdate(1);
    }
  };

  return (
    <form id='payment-form' onSubmit={handleSubmit}>
      {!ccOnFile && (
        <div>
          <div className='flex'>
            <input
              type='text'
              name='address'
              className='block w-full px-3 py-2 bg-white border border-slate-300 rounded-md text-sm shadow-sm placeholder-slate-400
              focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500
              disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none
              invalid:border-pink-500 invalid:text-pink-600
              focus:invalid:border-pink-500 focus:invalid:ring-pink-500'
              placeholder='Street Address'
              onChange={(val) => setAddress(val.target.value)}
            />
            {validator.current.message('Address', address, 'required', {
              className: 'text-danger',
            })}
          </div>
          <div className='flex'>
            <div className='basis-1/2'>
              <RegionDropdown
                classes='block w-full mt-2 px-3 py-2 bg-white border border-slate-300 rounded-md text-sm shadow-sm placeholder-slate-400
                focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500
                disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none
                invalid:border-pink-500 invalid:text-pink-600
                focus:invalid:border-pink-500 focus:invalid:ring-pink-500'
                country={country}
                value={region}
                defaultOptionLabel='Select State'
                valueType='short'
                countryValueType='short'
                onChange={(val) => setRegion(val)}
              />
            </div>
            <div className='basis-1/2 pl-1'>
              <input
                type='text'
                name='postal'
                className='block w-full mt-2 px-3 py-2 bg-white border border-slate-300 rounded-md text-sm shadow-sm placeholder-slate-400
                focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500
                disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none
                invalid:border-pink-500 invalid:text-pink-600
                focus:invalid:border-pink-500 focus:invalid:ring-pink-500'
                placeholder='Zip Code'
                value={postal}
                onChange={(val) => setPostal(val.target.value)}
              />
              {validator.current.message('Zip Code', postal, 'required', {
                className: 'text-danger',
              })}
            </div>
          </div>
          <div className='flex'>
            <CountryDropdown
              valueType='short'
              classes='block w-full mt-2 px-3 py-2 bg-white border border-slate-300 rounded-md text-sm shadow-sm placeholder-slate-400
              focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500
              disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none
              invalid:border-pink-500 invalid:text-pink-600
              focus:invalid:border-pink-500 focus:invalid:ring-pink-500'
              value={country}
              onChange={(val) => setCountry(val)}
            />
          </div>
          <div className='flex'>
            <CardElement
              className='block w-full mt-2 px-3 py-2 bg-white border border-slate-300 rounded-md text-sm shadow-sm placeholder-slate-400
              focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500
              disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none
              invalid:border-pink-500 invalid:text-pink-600
              focus:invalid:border-pink-500 focus:invalid:ring-pink-500'
              id='card-number'
              options={cardStyle}
              onChange={handleChange}
            />
          </div>
        </div>
      )}

      <div className='submit-area-2 row m0'>
        <button
          disabled={processing || disabled || succeeded}
          className={`btn btn-rounded btn-block ${processing ? ' active' : ''}`}
        >
          <span id='button-text'>
            {processing ? (
              <span>
                <i className='fa fa-spin fa-spinner'></i> Processing...
              </span>
            ) : (
              'Pay Now'
            )}
          </span>
        </button>
      </div>

      {/* Show any error that happens when processing the payment */}
      {error && (
        <div className='text-red-500 mt-1' role='alert'>
          {error}
        </div>
      )}
      {/* Show a success message upon completion */}
      <p className={succeeded ? 'result-message' : 'result-message hidden'}>
        Your payment succeeded
      </p>
    </form>
  );
}
