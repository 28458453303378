
const ScheduleFrame = () => {
  console.log("schedule frame....")

  return (
    <iframe
      title="schedule"
      src="https://utmsimple.com/schedule.html"
      className="min-h-[calc(100vh-228px)] mt-[24px] w-full"
    />
  );
};

export default ScheduleFrame;
