import { FormEvent, useRef, useState } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import StripeCheckoutForm from './StripeCheckoutForm';
import { AuthUser, signUp } from 'aws-amplify/auth';
import axios from 'axios';
import SimpleReactValidator from 'simple-react-validator';
import { loadStripe } from '@stripe/stripe-js';
import { dateReformat } from './Common';
import { Transition } from '@headlessui/react';

import './ProductDesc.css';
import { useAuthStore } from '../features/auth/AuthStore';
import { useSelectedPlanStore } from '../features/auth/SelectedPlanStore';

const promise = loadStripe(process.env.REACT_APP_STRIPE_KEY!);

declare global {
  interface Window {
    Cookies: any;
    gtag: any;
  }
}

const ProductDesc = () => {
  const date = new Date();
  const trial_end = date.setDate(date.getDate() + 7);
  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [ccSelected, setCcSelected] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [ccProcessing, setCcProcessing] = useState(false);
  const [paypalProcessing, setPaypalProcessing] = useState(false);
  const { Cookies, gtag } = window;

  const [isFormInvalid, setIsFormInvalid] = useState(false);

  const selected_plan = useSelectedPlanStore((state) => state.selected_plan);
  const title = useSelectedPlanStore((state) => state.title);
  const quantity = useSelectedPlanStore((state) => state.quantity);
  const product_price = useSelectedPlanStore((state) => state.product_price);
  const membership = useSelectedPlanStore((state) => state.membership);
  const paypal_plan_id = useSelectedPlanStore((state) => state.paypal_plan_id);
  const multiplier = useSelectedPlanStore((state) => state.multiplier);

  const setAuthUser = useAuthStore((state) => state.setAuthUser);
  const setPendingUser = useAuthStore((state) => state.setPendingUser);
  const pendingUser = useAuthStore((state) => state.pendingUser);

  const validationRef = useRef(
    new SimpleReactValidator({
      element: (message: string, className: string) => (
        <div className={className}>{message}</div>
      ),
      messages: {
        in: 'Passwords need to match!',
      },
    })
  );

  const onFirstStepSubmit = async (event: FormEvent) => {
    event.preventDefault();

    if (!validationRef.current.allValid()) {
      validationRef.current.showMessages();
      setIsFormInvalid(true);
      return;
    }

    setCcProcessing(true);
    setPaypalProcessing(false);
    setCcSelected(false);

    try {
      const user = await signUp({
        username: email,
        password: password,
        options: {
          userAttributes: {
            given_name: firstName,
            middle_name: '',
            'custom:utm_campaign': Cookies.get('utm_campaign') ?? 'NA',
            'custom:utm_medium': Cookies.get('utm_medium') ?? 'NA',
            'custom:utm_term': Cookies.get('utm_term') ?? 'NA',
            'custom:utm_content': Cookies.get('utm_content') ?? 'NA',
            'custom:utm_source': Cookies.get('utm_source') ?? 'NA',
            'custom:ip': Cookies.get('handl_js_ip') ?? 'NA',
            'custom:user_agent': Cookies.get('user_agent') ?? 'NA',
            'custom:_fbc': Cookies.get('_fbc') ?? 'NA',
            'custom:_fbp': Cookies.get('_fbp') ?? 'NA',
            'custom:gclid': Cookies.get('gclid') ?? 'NA',
            'custom:fbclid': Cookies.get('fbclid') ?? 'NA',
          },
        },
      });

      console.log(user);

      setAuthUser(user as unknown as AuthUser);
      gtag('event', 'view_item', {
        items: [
          {
            id: selected_plan,
            name: 'UTMSimple Tracker',
            brand: 'UTMSimple',
            variant: title,
            list_position: 2,
            quantity: quantity,
            price: `${product_price}.00`,
          },
        ],
        email: email,
      });

      gtag('event', 'generate_lead', {
        email: email,
      });
      // Event snippet for Subscribed For Free conversion page
      gtag('event', 'conversion', {
        send_to: 'AW-604754791/4njECKyF7u4CEOemr6AC',
        value: 1.0,
        currency: 'USD',
        transaction_id: '',
      });

      // console.log("triggered...")
      setErrorMsg('');
      setCcProcessing(false);

      setPendingUser({
        email,
        first_name: firstName,
      });
    } catch (error: any) {
      setErrorMsg(error.message);
      setCcProcessing(false);
      console.log('error signing up:', error);
    }
  };

  const onCCpay = () => {
    setCcSelected((state) => !state);
  };

  const onPayPalPay = async (event: any) => {
    event.preventDefault();
    setCcSelected(false);
    setPaypalProcessing(true);

    axios({
      method: 'POST',
      url: process.env.REACT_APP_API_ENDPOINT + 'http/charge',
      data: {
        pay_id: 'paypal',
        email: pendingUser?.email,
        planId: paypal_plan_id,
        membership: membership,
        quantity: quantity,
        multiplier: multiplier ? 2 : 1,
        type: 'sub',
      },
      // headers: await this.props.auth.getCognitoHeaders(),
    })
      .then((response) => {
        // console.log(response.data.links)
        if (response.status === 200) {
          gtag('event', 'begin_checkout', {
            items: [
              {
                id: selected_plan,
                name: 'UTMSimple Tracker',
                brand: 'UTMSimple',
                variant: title,
                list_position: 2,
                quantity: quantity,
                price: `${product_price}.00`,
              },
            ],
            email: email,
          });

          let approveLink = response.data.links.filter(
            (link: any) => link.rel === 'approve'
          );

          window.location = approveLink[0].href;
        }
      })
      .catch((error) => {
        // handle error
        setPaypalProcessing(false);
        console.log(error);
      });
  };

  return (
    <div className='choose-form row m0' id='paypal-regn'>
      <Transition
        show={pendingUser === null}
        enter='ease-out duration-300'
        enterFrom='opacity-0 scale-95'
        enterTo='opacity-100 scale-100'
        leave='ease-in duration-200'
        leaveFrom='opacity-100 scale-100'
        leaveTo='opacity-0 scale-95'
      >
        <div id='js-product-info' className='product-info'>
          <form method='POST' onSubmit={onFirstStepSubmit}>
            <div className=''>
              <p className='m-b-80 text-center'>
                Hi there 👋 <br />
                Please create an account to get started. <br />
                It's FREE!
              </p>
              <div className='form-group'>
                <input
                  type='text'
                  name='first_name'
                  className={`block w-full px-3 py-2 bg-white border border-slate-300 rounded-md text-sm shadow-sm placeholder-slate-400
                    focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500
                    disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none ${
                      isFormInvalid &&
                      !validationRef.current.fields['First Name'] &&
                      'invalid'
                    }`}
                  placeholder='First Name'
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                />
                {validationRef.current.message(
                  'First Name',
                  firstName,
                  'required',
                  {
                    className: 'text-red-500 text-left',
                  }
                )}
              </div>
              <div className='form-group'>
                <input
                  type='email'
                  name='email'
                  className={`block w-full px-3 py-2 bg-white border border-slate-300 rounded-md text-sm shadow-sm placeholder-slate-400
                    focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500
                    disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none ${
                      isFormInvalid &&
                      !validationRef.current.fields['Email Address'] &&
                      'invalid'
                    }`}
                  placeholder='Email Address'
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                {validationRef.current.message(
                  'Email Address',
                  email,
                  'required|email',
                  { className: 'text-red-500 text-left' }
                )}
              </div>

              <div className='form-group'>
                <input
                  type='password'
                  name='password'
                  className={`block w-full px-3 py-2 bg-white border border-slate-300 rounded-md text-sm shadow-sm placeholder-slate-400
                    focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500
                    disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none ${
                      isFormInvalid &&
                      !validationRef.current.fields['Password'] &&
                      'invalid'
                    }`}
                  placeholder='Password'
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                {validationRef.current.message(
                  'Password',
                  password,
                  'required|min:6',
                  { className: 'text-red-500 text-left' }
                )}
              </div>

              <div className='form-group'>
                <input
                  type='password'
                  name='confirm_password'
                  className={`block w-full px-3 py-2 bg-white border border-slate-300 rounded-md text-sm shadow-sm placeholder-slate-400
              focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500
              disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none ${
                isFormInvalid &&
                !validationRef.current.fields['Confirm Password'] &&
                'invalid'
              }`}
                  placeholder='Password (repeat)'
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
                {validationRef.current.message(
                  'Confirm Password',
                  confirmPassword,
                  `required|min:6|in:${password}`,
                  { className: 'text-red-500 text-left' }
                )}
              </div>
            </div>
            {errorMsg !== '' && (
              <p className='text-red-500 text-left'>{errorMsg}</p>
            )}
            <div className='text-sm font-light text-left mt-4'>
              {ccProcessing && (
                <button
                  className='flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'
                  id='next-personal'
                >
                  <i className='fa fa-spin fa-spinner'></i> Processing...
                </button>
              )}
              {!ccProcessing && (
                <input
                  type='submit'
                  className='flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'
                  value='Sign Up'
                />
              )}
            </div>
          </form>
        </div>
      </Transition>

      <Transition
        show={pendingUser !== null}
        enter='ease-out duration-300'
        enterFrom='opacity-0 scale-95'
        enterTo='opacity-100 scale-100'
        leave='ease-in duration-200'
        leaveFrom='opacity-100 scale-100'
        leaveTo='opacity-0 scale-95'
      >
        <div className='' id='js-personal-info'>
          <div className='text-left'>
            <h3 className='text-2xl'>Start your free trial of UTM Simple</h3>
            <h4 className='text-xl mt-2'>
              Get full access to UTM Simple {title} free for 7 days!
            </h4>
            <p className='mt-1'>
              Try our UTM Simple tracking with no risk and no commitment. We
              won't charge you until after your free trial and you can cancel
              anytime.
            </p>

            <p className='text-lime-600 fw-500 mt-1'>Today's charge: $0</p>
            <span className='small'>
              Then a total of ${1 * product_price}{' '}
              {membership ? 'per month' : ''} beginning on{' '}
              {dateReformat(trial_end)}.
            </span>
          </div>

          <div className='flex mt-5'>
            <div className='basis-1/2 p-2'>
              {paypalProcessing && (
                <svg
                  aria-hidden='true'
                  className='w-8 h-8 text-gray-200 animate-spin fill-white'
                  viewBox='0 0 100 101'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    d='M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z'
                    fill='currentColor'
                  />
                  <path
                    d='M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z'
                    fill='currentFill'
                  />
                </svg>
              )}
              {!paypalProcessing && (
                <button
                  className='w-full rounded-[30px] bg-[#ffe457] hover:text-white block text-black hover:bg-[#374048] text-[26px] font-semibold leading-[70px]'
                  id='next-personal'
                  onClick={onPayPalPay}
                >
                  <i className='fa fa-paypal'></i>&nbsp; PAYPAL
                </button>
              )}
            </div>
            <div className='basis-1/2 p-2'>
              <button
                className={`w-full rounded-[30px] hover:text-white block hover:bg-[#374048] text-[26px] font-semibold leading-[70px] ${
                  ccSelected
                    ? 'text-white bg-[#374048]'
                    : 'bg-[#ffe457] text-black'
                }`}
                id='next-personal'
                onClick={onCCpay}
              >
                <i className='fa fa-credit-card'></i>&nbsp; CREDIT CARD
              </button>
            </div>
          </div>

          <div className={`mt-2 ${ccSelected ? 'block' : 'hidden'}`}>
            <Elements stripe={promise}>
              <StripeCheckoutForm ccSelected={ccSelected} />
            </Elements>
          </div>
        </div>
      </Transition>
    </div>
  );
};

export default ProductDesc;
