import {
  Dialog,
  DialogPanel,
  DialogTitle,
  Transition,
  TransitionChild,
} from '@headlessui/react';
import cx from 'classnames';
import { Fragment } from 'react';

const Modal = ({
  isOpen,
  onAfterClosed,
  children,
  className,
  onClose = () => {},
  maskClassName,
  title,
  unmountOnClose = false,
}: {
  isOpen: boolean;
  onAfterClosed: () => void;
  children: React.ReactNode;
  className?: string;
  onClose?: (event: any) => void;
  maskClassName?: string;
  title: JSX.Element;
  unmountOnClose?: boolean;
}) => (
  <Transition appear show={isOpen} as={Fragment}>
    <Dialog
      as='div'
      className='relative z-[10000]'
      onClose={onClose}
      unmount={unmountOnClose}
    >
      <TransitionChild
        as={Fragment}
        enter='ease-out duration-300'
        enterFrom='opacity-0'
        enterTo='opacity-100'
        leave='ease-in duration-200'
        leaveFrom='opacity-100'
        leaveTo='opacity-0'
        afterLeave={onAfterClosed}
      >
        <button className='fixed inset-0 transition-opacity' onClick={onClose}>
          <div
            className={cx(
              'h-full w-full opacity-[0.65] !bg-black',
              maskClassName
            )}
          />
        </button>
      </TransitionChild>

      <div className='fixed inset-0 overflow-auto'>
        <div className='flex min-h-full items-center justify-center p-4 text-center'>
          <TransitionChild
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0 scale-95'
            enterTo='opacity-100 scale-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100 scale-100'
            leaveTo='opacity-0 scale-95'
          >
            <DialogPanel
              className={cx(
                'min-w-fit max-w-lg transform rounded-lg bg-white align-middle shadow overflow-clip',
                className
              )}
            >
              <DialogTitle
                as='h3'
                className='text-xl font-medium leading-6 text-gray-900 mt-6'
              >
                {title}
              </DialogTitle>
              <div className='flex h-full flex-col space-y-6'>{children}</div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </Transition>
);

export default Modal;
