import { fetchAuthSession } from 'aws-amplify/auth';
import { joinUrls } from '../join-url';

const changeEmail = async ({
  code,
  accessToken,
}: {
  code: string;
  accessToken: string;
}): Promise<any> => {
  const token = (await fetchAuthSession()).tokens?.idToken?.toString();

  const response = await fetch(
    joinUrls(process.env.REACT_APP_API_ENDPOINT!, 'http/users/change-email'),
    {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        code,
        accessToken,
      }),
    }
  );

  const data = await response.json();

  return data;
};

export { changeEmail };
