import { useState, useEffect } from 'react';
import Modal from './Modal';
import { useAuthStore } from '../features/auth/AuthStore';
import { useSelectedPlanStore } from '../features/auth/SelectedPlanStore';

declare global {
  interface Window {
    gtag: any;
  }
}

const { gtag } = window;

const ThankYou = ({
  token,
  subscription_id,
}: {
  token?: string;
  subscription_id?: string;
}) => {
  const [result, setResult] = useState(0);
  const [email, setEmail] = useState('');

  const selected_plan = useSelectedPlanStore((state) => state.selected_plan);
  const title = useSelectedPlanStore((state) => state.title);

  const hideThankYouModal = useAuthStore((state) => state.hideThankYouModal);
  const isThankYouModalVisible = useAuthStore(
    (state) => state.isThankYouModalVisible
  );
  const pendingUser = useAuthStore((state) => state.pendingUser);

  useEffect(() => {
    let orderid = token;
    let subscriptionId = subscription_id;
    //http://localhost:8080/?thanks=1&subscription_id=I-1RLTPW8WD4AW&ba_token=BA-70A74169AP622462M&token=1EM752978S7169450
    // http://localhost:8080/?thanks=1&token=88G27087EE5430913&PayerID=M5QFW22YRS9ZL
    if (orderid) {
      //This is PayPal
      fetch(process.env.REACT_APP_API_ENDPOINT + 'http/charge', {
        method: 'POST',
        // headers: await this.props.auth.getCognitoHeaders(),
        body: JSON.stringify({
          pay_id: 'paypal_capture',
          token: orderid,
          subscriptionId: subscriptionId,
        }),
      })
        .then(function (response) {
          if (!response.ok) {
            //setTimeout( function () {getClientSecret(tries+1)} , 500)
            throw Error(response.statusText);
          }
          return response;
        })
        .then((res) => {
          return res.json();
        })
        .then((data) => {
          if (data.status === 'COMPLETED' || data.status === 'ACTIVE') {
            setResult(1);
            setEmail(data.email);
            // TODO: get correct quantity from PayPal

            let amount, txid;
            let quantity = data.quantity;
            if (subscriptionId != '') {
              txid = data.result.id;
              if (data.result.billing_info.last_payment) {
                amount = data.result.billing_info.last_payment.amount.value;
              } else {
                amount = 1;
              }
            } else {
              txid = data.result.purchase_units[0].payments.captures[0].id;
              amount =
                data.result.purchase_units[0].payments.captures[0].amount.value;
            }
            console.log(quantity);
            console.log(amount);
            console.log(txid);

            gtag('event', 'purchase', {
              transaction_id: txid,
              value: amount,
              currency: 'USD',
              tax: 0.0,
              shipping: 0,
              items: [
                {
                  id: selected_plan,
                  name: 'HandL Tracker',
                  brand: 'HandL',
                  variant: title,
                  list_position: 2,
                  quantity: quantity,
                  price: amount,
                },
              ],
            });

            gtag('event', 'conversion', {
              send_to: 'AW-604754791/4njECKyF7u4CEOemr6AC',
              transaction_id: txid,
              value: amount,
              currency: 'USD',
            });
          } else if (data.status === 'ERROR') {
            //This is probably due to double processing, go check log :)
            setResult(-1);
          }
        })
        .catch(function () {
          //setTimeout( function () {getClientSecret (tries+1)} , 500)
          console.log('error');
        });
    } else {
      setResult(1);
      setEmail(pendingUser?.email!);
    }
  }, []);

  return (
    <Modal
      isOpen={isThankYouModalVisible}
      onClose={() => hideThankYouModal()}
      onAfterClosed={() => {}}
      maskClassName={'mask'}
      key={1}
      title={<></>}
      className={'thanks-popup'}
    >
      <div
        id='thanks-popup'
        className='lg:w-[700px] md:w-[500px] w-[400px] px-10 py-4'
      >
        <div className='flex w-full items-center justify-center'>
          <div
            className={`${
              result === -1 ? ' danger-bg' : 'success-bg'
            } fs-10 text-white`}
          >
            {result === 0 && (
              <i className='fa text-white fa-spin fa-spinner'></i>
            )}
            {result === 1 && <i className='fa text-white fa-check-circle'></i>}
            {result === -1 && (
              <i className='fa text-white fa-exclamation-circle'></i>
            )}
          </div>
        </div>
        <div className='flex w-full items-center justify-center'>
          <div className='p-t-20 p-b-20'>
            {result === 0 && (
              <div>
                <h1 className='text-center text-2xl'>
                  We are processing your payment...{' '}
                </h1>
                <p className='mt-2'>
                  Please hold while we are working on your order..
                </p>
              </div>
            )}
            {result === 1 && (
              <div>
                <h1 className='text-center text-2xl'>Awesome!</h1>
                <p className='mt-2'>
                  Thank you for purchasing. Your checkout is completed. <br />
                  Please go to My Account to get started
                </p>
              </div>
            )}

            {result === -1 && (
              <div>
                <h1 className='text-center text-2xl'>Uh oh! </h1>
                <p className='mt-2'>
                  Some unexpected problem, please inform support.
                </p>
              </div>
            )}

            {result !== 0 && (
              <button
                onClick={() => hideThankYouModal()}
                className={`text-white ${
                  result === -1 ? 'btn-danger' : 'btn-success'
                }  m-t-20 rounded-[30px] py-[2px] px-[33px] bg-gray-600 hover:bg-gray-800`}
              >
                OK
              </button>
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ThankYou;
