import React, { useEffect, Fragment, useState } from 'react';
import Logo from '../../images/logo.png';
import LogoW from '../../images/logo.webp';
import { signOut } from 'aws-amplify/auth';
import { ImgW } from '../Common';

import { Link, useLocation, useNavigate } from 'react-router-dom';

import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  Transition,
} from '@headlessui/react';
import { classNames } from '../../utils';

import { HiBars3, HiOutlineXMark } from 'react-icons/hi2';

import { useAuthStore } from '../../features/auth/AuthStore';
import { useQueryClient } from '@tanstack/react-query';

const navigation = [
  { name: 'FEATURES', href: '/#features', current: false },
  { name: 'PRICING', href: '/#prices', current: false },
  { name: 'DEMO', href: '/#schedule-expert', current: false },
  { name: 'FAQ', href: '/#faq', current: false },
  { name: 'CONTACT', href: '/#contact', current: false },
];

const Navbar = () => {
  const [isHome, setIsHome] = React.useState(true);
  const location = useLocation();
  const navigate = useNavigate();

  const showLoginForm = useAuthStore((state) => state.showLoginForm);
  const isAuthenticated = useAuthStore((state) => state.isAuthenticated);
  const makeIsAuthenticatedFalse = useAuthStore(
    (state) => state.makeIsAuthenticatedFalse
  );
  const removeAuthUser = useAuthStore((state) => state.removeAuthUser);
  const removePendingUser = useAuthStore((state) => state.removePendingUser);
  const queryClient = useQueryClient();

  useEffect(() => {
    if (location.hash) {
      const element = document.getElementById(location.hash.substring(1));
      if (element) {
        window.scrollTo(0, element.offsetTop - 100);
      }
    }
    setIsHome(location.pathname === '/');
  }, [location]);

  const userAttributes = useAuthStore((state) => state.userAttributes);
  const pendingUser = useAuthStore((state) => state.pendingUser);

  const username = pendingUser?.first_name || userAttributes.given_name || '';

  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const updatePosition = () => {
      const winScroll =
        document.body.scrollTop || document.documentElement.scrollTop;

      const height =
        document.documentElement.scrollHeight -
        document.documentElement.clientHeight;

      const scrolled = winScroll / height;
      setIsScrolled(scrolled ? true : false);
    };
    window.addEventListener('scroll', updatePosition);
    updatePosition();
    return () => window.removeEventListener('scroll', updatePosition);
  }, []);

  const onLogout = async () => {
    try {
      await signOut();
      removePendingUser();
      makeIsAuthenticatedFalse();
      removeAuthUser();
      queryClient.invalidateQueries({
        queryKey: ['accountInfo'],
      });
      navigate('/');
    } catch (error: any) {
      console.log(error.message);
    }
  };

  return (
    <header className='z-10' id='header'>
      <Disclosure
        as='nav'
        className={`fixed w-full top-0 transition-all duration-300 ease-linear ${
          isScrolled || !isHome
            ? 'bg-white text-gray-500 shadow-lg'
            : 'bg-000 text-white'
        }`}
      >
        {({ open }) => (
          <>
            <div
              className={`transition-all duration-300 ease-linear mx-auto max-w-7xl px-2 ${
                isScrolled || !isHome ? 'pt-4 pb-2' : 'py-6'
              } md:px-6 lg:px-8`}
            >
              <div className='relative flex h-16 items-center justify-between'>
                <div className='absolute inset-y-0 left-0 flex items-center md:hidden'>
                  {/* Mobile menu button*/}
                  <DisclosureButton className='relative inline-flex items-center justify-center rounded-md p-2 text-white bg-[#0c4edd5e] hover:bg-gray-300 hover:text-bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white'>
                    <span className='absolute -inset-0.5' />
                    <span className='sr-only'>Open main menu</span>
                    {open ? (
                      <HiOutlineXMark size={24} />
                    ) : (
                      <HiBars3 size={24} />
                    )}
                  </DisclosureButton>
                </div>
                <Link
                  to='/'
                  onClick={() => window.scrollTo(0, 0)}
                  className='flex flex-1 items-center justify-center pl-4 md:pl-8 md:items-stretch md:justify-start cursor-pointer'
                >
                  <div
                    className={`transition-all duration-300 ease-linear flex flex-shrink-0 items-center ${
                      isScrolled || !isHome
                        ? 'h-[40px] w-[100px]'
                        : 'h-[55px] w-[150px]'
                    }`}
                  >
                    <ImgW
                      src={LogoW}
                      fallback={Logo}
                      alt='HandL Tracker Logo'
                      className={`${
                        isScrolled || !isHome ? 'bg-[#6b30e9]' : 'bg-opacity-0'
                      } transition-all duration-300 ease-linear`}
                    />
                  </div>
                </Link>
                <div className='absolute inset-y-0 right-0 flex items-center pr-2 md:static md:inset-auto md:ml-6 md:pr-0'>
                  <div className='hidden md:ml-6 md:block'>
                    <div className='flex space-x-4'>
                      {navigation.map((item) => (
                        <Link
                          key={item.name}
                          to={item.href}
                          className={classNames(
                            item.current ? '' : 'hover:text-gray-300',
                            'rounded-md px-3 py-2 text-[16px] font-medium'
                          )}
                          aria-current={item.current ? 'page' : undefined}
                        >
                          {item.name}
                        </Link>
                      ))}
                    </div>
                  </div>
                  {isAuthenticated || pendingUser ? (
                    <Menu as='div' className='relative ml-3'>
                      <div>
                        <MenuButton className='relative flex text-[16px] px-3 py-2 font-medium outline-none hover:outline-none focus:outline-none'>
                          <span className='absolute -inset-1.5' />
                          <span className='sr-only'>Open user menu</span>
                          HELLO, {username.toUpperCase()}{' '}
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            fill='none'
                            viewBox='0 0 24 24'
                            strokeWidth={1.5}
                            stroke='currentColor'
                            className='w-3 h-3 ml-1 m-auto'
                          >
                            <path
                              strokeLinecap='round'
                              strokeLinejoin='round'
                              d='m19.5 8.25-7.5 7.5-7.5-7.5'
                            />
                          </svg>
                        </MenuButton>
                      </div>
                      <Transition
                        as={Fragment}
                        enter='transition ease-out duration-100'
                        enterFrom='transform opacity-0 scale-95'
                        enterTo='transform opacity-100 scale-100'
                        leave='transition ease-in duration-75'
                        leaveFrom='transform opacity-100 scale-100'
                        leaveTo='transform opacity-0 scale-95'
                      >
                        <MenuItems className='absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none'>
                          <MenuItem>
                            {({ focus }) => (
                              <Link
                                to='/infos'
                                className={classNames(
                                  focus ? 'bg-gray-100' : '',
                                  'block px-4 py-2 text-sm text-gray-700 cursor-pointer'
                                )}
                              >
                                MY ACCOUNT
                              </Link>
                            )}
                          </MenuItem>
                          <MenuItem>
                            {({ focus }) => (
                              <button
                                onClick={onLogout}
                                className={classNames(
                                  focus ? 'bg-gray-100' : '',
                                  'block w-full text-left px-4 py-2 text-sm text-gray-700 cursor-pointer'
                                )}
                              >
                                SIGN OUT
                              </button>
                            )}
                          </MenuItem>
                        </MenuItems>
                      </Transition>
                    </Menu>
                  ) : (
                    <button
                      onClick={() => showLoginForm()}
                      className='rounded-md px-3 py-2 text-[16px] font-medium hover:text-gray-300'
                    >
                      LOGIN
                    </button>
                  )}
                </div>
              </div>
            </div>

            <DisclosurePanel className='md:hidden bg-[#6b30e933]'>
              <div className='space-y-1 px-2 pb-3 pt-2'>
                {navigation.map((item) => (
                  <DisclosureButton
                    key={item.name}
                    as={Link}
                    to={item.href}
                    className={classNames(
                      item.current
                        ? 'bg-gray-900 text-white'
                        : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                      'block rounded-md px-3 py-2 text-base font-medium text-black'
                    )}
                    aria-current={item.current ? 'page' : undefined}
                  >
                    {item.name}
                  </DisclosureButton>
                ))}
              </div>
            </DisclosurePanel>
          </>
        )}
      </Disclosure>
    </header>
  );
};

export default Navbar;
