import { useState, MouseEvent } from 'react';
import GooglePartnerBadge from '../../../images/GooglePartnerBadge.png';
import GooglePartnerBadge2x from '../../../images/GooglePartnerBadge@2x.png';

export default function Banner() {
  const [adjustPos, setAdjustPos] = useState([0, 0]);

  const onMouseMove = (e: MouseEvent) => {
    const amountMovedX = (e.pageX * -0.1) / 4;
    const amountMovedY = (e.pageY * -0.1) / 4;
    setAdjustPos([amountMovedX, amountMovedY]);
  };

  return (
    <div className='md:col-span-7 col-span-12'>
      <div
        className={`banner-slider`}
        onMouseMove={onMouseMove}
        style={{ transform: `translate(${adjustPos[0]}px, ${adjustPos[1]}px)` }}
      >
        <img
          className='google-partner inline'
          src={GooglePartnerBadge}
          srcSet={`${GooglePartnerBadge} 1x, ${GooglePartnerBadge2x} 2x`}
          alt='HandL Digital - proud Google Partner'
        />
      </div>
    </div>
  );
}
